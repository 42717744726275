import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import css from "./TeamExperts.module.css";
import vishal from "../../assets/vishal.jpeg";
import chetan from "../../assets/chetan.jpeg";
import vikash from "../../assets/vikash.jpeg";
import suneet from "../../assets/suneet.jpeg";
import shivam from "../../assets/shivam.jpeg";
import alok from "../../assets/alok.jpeg";

const TeamExperts = () => {
  return (
    <>
      <Header />
      <>
        <div className={css.team_experts}>
          <h2 className={css.team_expert_heading}>MEET THE TEAM</h2>
          <div className={css.team_experts_top}>
            <p className={css.experts_heading}>CORE MEMBERS</p>
            <div className={css.experts_container}>
              <div className={css.single_expert}>
                <img
                  src={vishal}
                  alt="Nixonbit Cybersecurity"
                  className={css.expert_img}
                />
                <div className={css.expert_details}>
                  <div className={css.expert_name}>vishal patel</div>
                  <div className={css.expert_job}>CEO/CO- founder</div>
                </div>
              </div>
              <div className={css.single_expert}>
                <img
                  src={alok}
                  alt="Nixonbit Cybersecurity"
                  className={css.expert_img}
                />
                <div className={css.expert_details}>
                  <div className={css.expert_name}>Alok singhal</div>
                  <div className={css.expert_institute}>(IIT BHU)</div>
                  <div className={css.expert_job}>
                    Development operations engineer
                  </div>
                </div>
              </div>
              <div className={css.single_expert}>
                <img
                  src={shivam}
                  alt="Nixonbit Cybersecurity"
                  className={css.expert_img}
                />
                <div className={css.expert_details}>
                  <div className={css.expert_name}>shivam mehta</div>
                  <div className={css.expert_institute}>(IIT BHU)</div>
                  <div className={css.expert_job}>senior software engineer</div>
                </div>
              </div>
              <div className={css.single_expert}>
                <img
                  src={chetan}
                  alt="Nixonbit Cybersecurity"
                  className={css.expert_img}
                />
                <div className={css.expert_details}>
                  <div className={css.expert_name}>chetan swaroop </div>
                  <div className={css.expert_institute}>(IIT BHU)</div>
                  <div className={css.expert_job}>Database administrator</div>
                </div>
              </div>
            </div>
          </div>
          <div className={css.team_experts_bottom}>
            <p className={css.experts_heading}>BACKED BY</p>
            <div className={css.experts_container}>
              <div className={css.single_expert}>
                <img
                  src={suneet}
                  alt="Nixonbit Cybersecurity"
                  className={css.expert_img}
                />
                <div className={css.expert_details}>
                  <div className={css.expert_name}>Sunit Tiwari</div>
                  <div className={css.expert_institute}>(IIT BHU)</div>
                  <div className={css.expert_job}>
                    Former software engineer (Samsung R&D), ITBP (Assistant
                    commandant )
                  </div>
                </div>
              </div>
              <div className={css.single_expert}>
                <img
                  src={vikash}
                  alt="Nixonbit Cybersecurity"
                  className={css.expert_img}
                />
                <div className={css.expert_details}>
                  <div className={css.expert_name}>Vikash Kumar</div>
                  <div className={css.expert_job}>National Defence Academy</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <Footer />
    </>
  );
};

export default TeamExperts;
