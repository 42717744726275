import React from "react";
import css from "./MiddleSection.module.css";
import ai1 from "../../../assets/ai1.png";
import ai2 from "../../../assets/ai2.png";
import ai3 from "../../../assets/ai3.png";

const MiddleSection = () => {
  return (
    <>
      <div className={css.maindiv}>
        <div className={css.header}>
          <p1 className={css.para1}>OUR AI SERVICES:</p1>
        </div>

        <div className={css.container}>
          <div className={css.imgborder}>
            <img src={ai1} className={css.image1} alt="Nixonbit Audit"></img>
          </div>
          <div className={css.div1}>
            <p1 className={css.para2}>CHATBOT SOLUTIONS</p1>
            <div className={css.div2}>
              <ul className={css.list}>
                <li>
                  Our chatbot solutions are designed to revolutionize customer
                  <br></br>
                  interactions, providing seamless support and assistance across
                  various platforms.<br></br> With expertise in natural language
                  understanding (NLU) and question-answering (QnA) systems,{" "}
                  <br></br>our chatbots excel in data analysis, NLQ (natural
                  language
                  <br></br>
                  queries), and data retrieval from various sources, including
                  <br></br>
                  PDFs, Word documents, and text files. Whether it's addressing
                  customer queries,<br></br> providing product recommendations,
                  or assisting with data retrieval tasks,<br></br> our chatbots
                  are equipped to handle diverse challenges efficiently.
                </li>
                {/* <li>
                  Advanced Capabilities: Leveraging NLU and QnA systems, our
                  chatbots<br></br>excel in data analysis and retrieval from
                  multiple sources.
                </li>
                <li>
                  {" "}
                  Versatile Solutions: From addressing queries to providing
                  recommendations,<br></br> our chatbots efficiently handle
                  diverse tasks.
                </li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className={css.container}>
          <div className={css.div1}>
            <p1 className={css.para2}>
              RECOMMENDATION SYSTEMS AND PREDICTIVE ANALYSIS
            </p1>
            <div className={css.div2}>
              <ul className={css.list}>
                <li>
                  Harnessing the power of AI/ML algorithms, we develop robust
                  <br></br>
                  recommendation systems that analyze user behavior and
                  preferences<br></br> to offer personalized recommendations.
                  <br></br> By leveraging predictive analysis techniques,
                  <br></br> our systems can anticipate user needs and
                  preferences, <br></br>
                  enabling businesses to enhance customer satisfaction and drive
                  sales.
                </li>
                {/* <li>
                  Predictive analysis techniques anticipate user needs, enabling
                  <br></br>
                  proactive adaptation of business offerings.{" "}
                </li>
                <li>
                  Utilizing AI/ML algorithms, businesses can drive sales by
                  <br></br>
                  delivering tailored recommendations based on user preferences.{" "}
                </li> */}
              </ul>
            </div>
          </div>

          <div className={css.imgborder}>
            <img
              src={ai2}
              className={css.image1}
              alt="Nixonbit Digital marketing"
            ></img>
          </div>
        </div>

        <div className={css.container}>
          <div className={css.imgborder}>
            <img
              src={ai3}
              className={css.image1}
              alt="Nixonbit Digital marketing"
            ></img>
          </div>
          <div className={css.div1}>
            <p1 className={css.para2}>COMPUTER VISION SOLUTIONS</p1>
            <div className={css.div2}>
              <ul className={css.list}>
                <li>
                  Our computer vision solutions empower businesses with advanced
                  <br></br>
                  capabilities in image analysis and processing.<br></br> From
                  object detection and image classification to image
                  segmentation,<br></br> our computer vision algorithms deliver
                  accurate and reliable results, enabling applications<br></br>{" "}
                  across various industries, including retail,<br></br>{" "}
                  healthcare, and manufacturing.
                </li>
                {/* <li>
                  Our algorithms excel in tasks like object detection, image
                  classification,<br></br> and segmentation, ensuring accurate
                  and reliable results.
                </li>
                <li>
                  Applications span industries such as retail, healthcare, and
                  manufacturing,<br></br> facilitating diverse use cases for
                  improved efficiency and innovation.
                </li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className={css.container}>
          <div className={css.div1}>
            <p1 className={css.para2}>
              TARGETED MARKETING AND CUSTOMER SEGMENTATION
            </p1>
            <div className={css.div2}>
              <ul className={css.list}>
                <li>
                  With our targeted marketing solutions, businesses can optimize
                  <br></br>
                  their marketing strategies and <br></br>campaigns to reach the
                  right audience at the right time.<br></br> Leveraging AI/ML
                  algorithms, we analyze customer data to identify patterns and
                  segments,<br></br>
                  enabling businesses to tailor their marketing efforts for
                  <br></br>
                  maximum impact and ROI.
                </li>
                {/* <li>
                  AI/ML algorithms analyze customer data, identifying patterns
                  <br></br>
                  and segments for tailored marketing.
                </li>
                <li>
                  Businesses can enhance impact and ROI by customizing marketing
                  <br></br>
                  efforts based on insightful data analysis.
                </li> */}
              </ul>
            </div>
          </div>

          <div className={css.imgborder}>
            <img
              src={ai1}
              className={css.image1}
              alt="Nixonbit Digital marketing"
            ></img>
          </div>
        </div>

        {/* <div className={css.container}>
          <div className={css.imgborder}>
            <img
              src={ai2}
              className={css.image1}
              alt="Nixonbit Digital marketing"
            ></img>
          </div>
          <div className={css.div1}>
            <p1 className={css.para2}>CUSTOM AI SOLUTIONS</p1>
            <div className={css.div2}>
              <ul className={css.list}>
                <li>Tailored AI development for unique business challenges</li>
                <li>AI model training and deployment</li>
                <li>Ongoing support and optimization</li>
              </ul>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default MiddleSection;
