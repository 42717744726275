import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import css from "./ForensicTools.module.css";
import img from "../../assets/img.jpg";
import img1 from "../../assets/img1.jpg";
import img2 from "../../assets/img2.jpg";
import img3 from "../../assets/img3.jpg";
import img4 from "../../assets/img4.jpg";
import img5 from "../../assets/img5.jpg";
import img6 from "../../assets/img6.jpg";
import img7 from "../../assets/img7.jpg";
import img8 from "../../assets/img8.jpg";
import img9 from "../../assets/img9.jpg";

const ForensicTools = () => {
  return (
    <>
      <Header />
      <>
        <div className={css.content}>
          <div className={css.content_top}>
            <div className={css.content_top_headings}>
              <h2 className={css.content_top_heading}>
                Forensic Tools Solutions
              </h2>
              <p className={css.content_top_para}>
                We provide cutting-edge forensic tools that ensure precision,
                reliability, and efficiency in digital investigations.
              </p>
            </div>
            <div className={css.content_top_box}>
              <div className={css.content_top_box_left_cir}>
                <img
                  src={img}
                  className={css.forensic_tools_img}
                  alt="Forensic Tools"
                />
              </div>
              <div className={css.content_top_box_right}>
                <p className={css.content_top_box_para}>
                  Our team of experts has the experience and know-how to develop
                  forensic solutions tailored to your investigative needs. We
                  help create forensic applications that are secure, scalable,
                  and easy to use.
                </p>
              </div>
            </div>
            <div className={css.content_top_box}>
              <div className={css.content_top_box_left}>
                <h3 className={css.content_top_box_heading}>
                  Digital Forensics
                </h3>
                <p className={css.content_top_box_para}>
                  Digital forensics involves the recovery and investigation of
                  material found in digital devices. Our solutions ensure that
                  your digital investigations are thorough and reliable.
                </p>
              </div>
              <div className={css.content_top_box_right}>
                <img
                  src={img1}
                  alt="Digital Forensics"
                  className={css.forensic_tools_img}
                />
              </div>
            </div>
            <div className={css.content_top_box}>
              <div className={css.content_top_box_left}>
                <img
                  src={img2}
                  alt="Mobile Forensics"
                  className={css.forensic_tools_img}
                />
              </div>
              <div className={css.content_top_box_right}>
                <h3 className={css.content_top_box_heading}>
                  Mobile Forensics
                </h3>
                <p className={css.content_top_box_para}>
                  Mobile forensics focuses on the recovery of digital evidence
                  from mobile devices. Our solutions ensure that data from
                  mobile devices is accurately extracted and analyzed.
                </p>
              </div>
            </div>
            <div className={css.content_top_box}>
              <div className={css.content_top_box_left}>
                <h3 className={css.content_top_box_heading}>
                  Network Forensics
                </h3>
                <p className={css.content_top_box_para}>
                  Network forensics involves monitoring and analysis of computer
                  network traffic for the purposes of information gathering,
                  legal evidence, or intrusion detection. Our tools provide
                  comprehensive network forensic capabilities.
                </p>
              </div>
              <div className={css.content_top_box_right}>
                <img
                  src={img3}
                  alt="Network Forensics"
                  className={css.forensic_tools_img}
                />
              </div>
            </div>
            <div className={css.content_top_box}>
              <div className={css.content_top_box_left}>
                <img
                  src={img4}
                  alt="Incident Response"
                  className={css.forensic_tools_img}
                />
              </div>
              <div className={css.content_top_box_right}>
                <h3 className={css.content_top_box_heading}>
                  Incident Response
                </h3>
                <p className={css.content_top_box_para}>
                  Incident response is a structured approach to handle and
                  manage security breaches or attacks. Our solutions enable you
                  to quickly respond to incidents and minimize damage.
                </p>
              </div>
            </div>
            <div className={css.content_top_box}>
              <div className={css.content_top_box_left}>
                <h3 className={css.content_top_box_heading}>Cloud Forensics</h3>
                <p className={css.content_top_box_para}>
                  Cloud forensics deals with the investigation of cloud
                  computing environments. Our tools help you effectively gather
                  and analyze data from cloud services.
                </p>
              </div>
              <div className={css.content_top_box_right}>
                <img
                  src={img5}
                  alt="Cloud Forensics"
                  className={css.forensic_tools_img}
                />
              </div>
            </div>
            <div className={css.content_top_bottom_headings}>
              <h3 className={css.content_top_bottom_heading}>
                Comprehensive Forensic Solutions for Your IT Ecosystem
              </h3>
              <p className={css.content_top_bottom_para}>
                Complete care of your digital forensics needs, whether
                on-premises or in the cloud.
              </p>
            </div>
          </div>

          <div className={css.content_top_mbl}>
            <div className={css.content_top_headings_mbl_headings}>
              <h2 className={css.content_top_heading_mbl}>
                Forensic Tools Solutions
              </h2>
              <p className={css.content_top_para_mbl}>
                We provide cutting-edge forensic tools that ensure precision,
                reliability, and efficiency in digital investigations.
              </p>
            </div>
            <div className={css.content_top_box_mbl1}>
              <img
                src="https://example.com/forensic_tools_img.png"
                className={css.forensic_tools_img_mbl}
                alt="Forensic Tools"
              />
              <p className={css.content_top_box_para_mbl1}>
                Our team of experts has the experience and know-how to develop
                forensic solutions tailored to your investigative needs. We help
                create forensic applications that are secure, scalable, and easy
                to use.
              </p>
            </div>
            <div className={css.content_top_box_mbl}>
              <img
                src="https://example.com/forensic_img1.png"
                alt="Digital Forensics"
                className={css.forensic_tools_img_mbl}
              />
              <h3 className={css.content_top_box_heading_mbl}>
                Digital Forensics
              </h3>
              <p className={css.content_top_box_para_mbl}>
                Digital forensics involves the recovery and investigation of
                material found in digital devices. Our solutions ensure that
                your digital investigations are thorough and reliable.
              </p>
            </div>
            <div className={css.content_top_box_mbl}>
              <img
                src="https://example.com/forensic_img2.png"
                alt="Mobile Forensics"
                className={css.forensic_tools_img_mbl}
              />
              <h3 className={css.content_top_box_heading_mbl}>
                Mobile Forensics
              </h3>
              <p className={css.content_top_box_para_mbl}>
                Mobile forensics focuses on the recovery of digital evidence
                from mobile devices. Our solutions ensure that data from mobile
                devices is accurately extracted and analyzed.
              </p>
            </div>
            <div className={css.content_top_box_mbl}>
              <img
                src="https://example.com/forensic_img3.png"
                alt="Network Forensics"
                className={css.forensic_tools_img_mbl}
              />
              <h3 className={css.content_top_box_heading_mbl}>
                Network Forensics
              </h3>
              <p className={css.content_top_box_para_mbl}>
                Network forensics involves monitoring and analysis of computer
                network traffic for the purposes of information gathering, legal
                evidence, or intrusion detection. Our tools provide
                comprehensive network forensic capabilities.
              </p>
            </div>
            <div className={css.content_top_box_mbl}>
              <img
                src="https://example.com/forensic_img4.png"
                alt="Incident Response"
                className={css.forensic_tools_img_mbl}
              />
              <h3 className={css.content_top_box_heading_mbl}>
                Incident Response
              </h3>
              <p className={css.content_top_box_para_mbl}>
                Incident response is a structured approach to handle and manage
                security breaches or attacks. Our solutions enable you to
                quickly respond to incidents and minimize damage.
              </p>
            </div>
            <div className={css.content_top_box_mbl}>
              <img
                src="https://example.com/forensic_img5.png"
                alt="Cloud Forensics"
                className={css.forensic_tools_img_mbl}
              />
              <h3 className={css.content_top_box_heading_mbl}>
                Cloud Forensics
              </h3>
              <p className={css.content_top_box_para_mbl}>
                Cloud forensics deals with the investigation of cloud computing
                environments. Our tools help you effectively gather and analyze
                data from cloud services.
              </p>
            </div>
          </div>
          <div className={css.content_middle}>
            <h2 className={css.content_heading}>GETTING STARTED</h2>
            <div className={css.content_middle_cards}>
              <div className={css.middle_left_cards}>
                <div className={css.middle_left_cards_img}>
                  <img
                    src={img6}
                    alt="Getting Started"
                    className={css.forensic_tools_img}
                  />
                </div>
                <div className={css.middle_left_cards_content}>
                  <h3 className={css.middle_left_cards_content_heading}>
                    Step 1
                  </h3>
                  <p className={css.middle_left_cards_content_para}>
                    Understand your forensic investigation needs and identify
                    the right tools.
                  </p>
                </div>
              </div>
              <div className={css.middle_left_cards}>
                <div className={css.middle_left_cards_img}>
                  <img
                    src={img7}
                    alt="Getting Started"
                    className={css.forensic_tools_img}
                  />
                </div>
                <div className={css.middle_left_cards_content}>
                  <h3 className={css.middle_left_cards_content_heading}>
                    Step 2
                  </h3>
                  <p className={css.middle_left_cards_content_para}>
                    Configure the forensic tools to align with your specific
                    requirements.
                  </p>
                </div>
              </div>
              <div className={css.middle_left_cards}>
                <div className={css.middle_left_cards_img}>
                  <img
                    src={img8}
                    alt="Getting Started"
                    className={css.forensic_tools_img}
                  />
                </div>
                <div className={css.middle_left_cards_content}>
                  <h3 className={css.middle_left_cards_content_heading}>
                    Step 3
                  </h3>
                  <p className={css.middle_left_cards_content_para}>
                    Implement and deploy the tools, ensuring proper integration
                    with your IT environment.
                  </p>
                </div>
              </div>
              <div className={css.middle_left_cards}>
                <div className={css.middle_left_cards_img}>
                  <img
                    src={img9}
                    alt="Getting Started"
                    className={css.forensic_tools_img}
                  />
                </div>
                <div className={css.middle_left_cards_content}>
                  <h3 className={css.middle_left_cards_content_heading}>
                    Step 4
                  </h3>
                  <p className={css.middle_left_cards_content_para}>
                    Train your team on how to effectively use the forensic tools
                    for investigations.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={css.content_bottom}>
            <h2 className={css.content_heading}>
              Get In Touch With Our Forensic Experts
            </h2>
            <p className={css.content_para}>
              Contact us to learn more about our forensic tools and how we can
              assist with your digital investigations.
            </p>
          </div>
        </div>
      </>
      <Footer />
    </>
  );
};

export default ForensicTools;
