import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import css from "./Products.module.css";
import be_alive1 from "../../assets/be_alive1.jpg";
import e_clump from "../../assets/e_clump.jpg";

const Products = () => {
  return (
    <>
      <Header />
      <>
        <div className={css.products}>
          <h1 className={css.product_heading}>OUR PRODUCTS</h1>
          <div className={css.products_container}>
            <div className={css.single_product}>
              <div className={css.img_box}>
                <img
                  src={e_clump}
                  alt="Nixonbit Digital marketing"
                  className={css.product_img}
                />
              </div>
              <div className={css.product_details}>
                <h2 className={css.prod_name}>ECLUMP</h2>
                <p className={css.prod_desc}>
                  Description: Eclump is a single platform for digital
                  management of all your school and college related work
                </p>
                <div className={css.prod_btns}>
                  <button className={css.prod_btn}>UI/UX DESIGN</button>
                  <button className={css.prod_btn}>devops</button>
                  <button className={css.prod_btn}>mobile development</button>
                  <button className={css.prod_btn}>web app development</button>
                </div>
              </div>
            </div>
            <div className={css.single_product_right}>
              <div className={css.img_box}>
                <img
                  src={be_alive1}
                  alt="Nixonbit Digital marketing"
                  className={css.product_img}
                />
              </div>
              <div className={css.product_details}>
                <h2 className={css.prod_name}>BEALIVE</h2>
                <p className={css.prod_desc}>
                  Description: BeAlive is a social networking service with nft
                  and other unique features that bring life to your connections
                  and explore your passion in true means
                </p>
                <div className={css.prod_btns}>
                  <button className={css.prod_btn}>UI/UX DESIGN</button>
                  <button className={css.prod_btn}>mobile development</button>
                </div>
              </div>
            </div>

            <div className={css.single_product}>
              <div className={css.img_box}>
                <img
                  src={e_clump}
                  alt="Nixonbit Digital marketing"
                  className={css.product_img}
                />
              </div>
              <div className={css.product_details}>
                <h2 className={css.prod_name}>CATCH</h2>
                <p className={css.prod_desc}>
                  Description: C.A.T.C.H uses Call Detail Records (CDR) to
                  enhance law enforcement with advanced tracking and
                  investigation, employing cutting-edge technology for efficient
                  crime combat and rapid incident responses, setting a new
                  standard in forensic technology for safer communities.
                </p>
                <div className={css.prod_btns}>
                  <button className={css.prod_btn}>UI/UX DESIGN</button>
                  <button className={css.prod_btn}>devops</button>
                  <button className={css.prod_btn}>mobile development</button>
                  <button className={css.prod_btn}>teligaram bot</button>

                  <button className={css.prod_btn}>web app development</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <Footer />
    </>
  );
};

export default Products;
