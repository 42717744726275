import css from "./First.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import sqa1 from "../../assets/sqa1.png";
import first_bottom from "../../assets/first_bottom.png";
import bank from "../../assets/bank.png";
import bank2 from "../../assets/bank2.png";
import bank4 from "../../assets/bank4.png";
import bank3 from "../../assets/bank3.png";
import bank5 from "../../assets/bank5.png";
import bank6 from "../../assets/bank6.png";
import bank7 from "../../assets/bank7.png";
import bank8 from "../../assets/bank8.png";
import bank9 from "../../assets/bank9.png";
import bank0 from "../../assets/bank0.png";
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons"; // Import the icon you need
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import inxonbit_image from "../../assets/inxonbit_image.png";

const First = () => {
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();

  const showMoreFn = () => {
    setShowMore(!showMore);
  };

  return (
    <div className={css.blockchain}>
      <div className={css.blockchain_top}>
        <div className={css.top_left}>
          <h2 className={css.top_heading}>BUILD, DEPLOY AND GROW YOUR DAPPS</h2>
          <p className={css.top_para}>
            whether you’re building a simple ERC20 token, NFT minting or a
            bussiness- critical product like NFT marketplace. decentralised
            exchanges or decentralised databases.
          </p>
          <button
            className={css.top_btn}
            onClick={() => {
              navigate("/contact");
            }}
          >
            Get Started
          </button>
        </div>
        <div className={css.top_right}>
          <img
            src={inxonbit_image}
            alt="Nixonbit Blockchain"
            // className={css.aquare}
          />
          {/* <img
            src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/blockchain_dots.png"
            alt="Nixonbit Blockchain"
            className={css.dots}
          />
          <img
            src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/blockchain_img1.png"
            alt="Nixonbit Blockchain"
            className={css.img1}
          /> */}
        </div>
        {/* <div className={css.top_right_mbl}>
          <img
            src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/mobile_hero.png"
            alt="Nixonbit Blockchain"
            className={css.top_right_mbl_img}
          />
        </div> */}
      </div>
      <div className={css.blockchain_middle}>
        <p className={css.middle_desc}>
          web3 and blockchain demand There is a large demand for both web3 and
          blockchain and they are in high demand across a wide variety of
          industries. The most popular industries for web3 and blockchain
          developers include:
        </p>

        <div className={css.middle_details}>
          <div className={css.details_container}>
            <div className={css.img_redius}>
              <img src={bank} alt="Nixonbit Blockchain" />
            </div>
            <p className={css.details_para}>Banking and financial services</p>
          </div>
          <div className={css.details_container}>
            <div className={css.img_redius}>
              <img src={bank2} alt="Nixonbit Blockchain" />
            </div>
            <p className={css.details_para}>Supply chain and logistics</p>
          </div>
          <div className={css.details_container}>
            <div className={css.img_redius}>
              <img src={bank4} alt="Nixonbit Blockchain" />
            </div>
            <p className={css.details_para}>Healthcare </p>
          </div>
          <div className={css.details_container}>
            <div className={css.img_redius}>
              <img src={bank3} alt="Nixonbit Blockchain" />
            </div>
            <p className={css.details_para}>Data and analytics</p>
          </div>
          {showMore && (
            <>
              <div className={css.details_container}>
                <div className={css.img_redius}>
                  <img src={bank5} alt="Nixonbit Blockchain" />
                </div>
                <p className={css.details_para}>Enterprise software</p>
              </div>
              <div className={css.details_container}>
                <div className={css.img_redius}>
                  <img src={bank6} alt="Nixonbit Blockchain" />
                </div>
                <p className={css.details_para}>IoT and connected devices</p>
              </div>
              <div className={css.details_container}>
                <div className={css.img_redius}>
                  <img src={bank7} alt="Nixonbit Blockchain" />
                </div>
                <p className={css.details_para}>Government</p>
              </div>
              <div className={css.details_container}>
                <div className={css.img_redius}>
                  <img src={bank8} alt="Nixonbit Blockchain" />
                </div>
                <p className={css.details_para}>Media and entertainment</p>
              </div>
              <div className={css.details_container}>
                <div className={css.img_redius}>
                  <img src={bank9} alt="Nixonbit Blockchain" />
                </div>
                <p className={css.details_para}>Retail</p>
              </div>
              <div className={css.details_container}>
                <div className={css.img_redius}>
                  <img src={bank0} alt="Nixonbit Blockchain" />
                </div>
                <p className={css.details_para}>Telecommunications</p>
              </div>
            </>
          )}
        </div>

        <button onClick={showMoreFn} className={css.middle_btn}>
          {showMore ? "Show Less" : "Show More"}
        </button>
      </div>
      <div className={css.blockchain_bottom}>
        <p className={css.bottom_para}>
          The most popular skills for web3 and blockchain we provide:
        </p>
        <div className={css.bottom_lists}>
          <div className={css.bottom_details}>
            <div className={css.circle_container}>
              <FontAwesomeIcon icon={faHandPointRight} className={css.icon} />
            </div>
            <p className={css.list_name}>Smart contract development</p>
          </div>{" "}
          <div className={css.bottom_details}>
            <div className={css.circle_container}>
              <FontAwesomeIcon icon={faHandPointRight} className={css.icon} />
            </div>
            <p className={css.list_name}>Cryptography</p>
          </div>
          <div className={css.bottom_details}>
            <div className={css.circle_container}>
              <FontAwesomeIcon icon={faHandPointRight} className={css.icon} />
            </div>
            <p className={css.list_name}>Web3.js</p>
          </div>
          <div className={css.bottom_details}>
            <div className={css.circle_container}>
              <FontAwesomeIcon icon={faHandPointRight} className={css.icon} />
            </div>
            <p className={css.list_name}>Ethereum</p>
          </div>
          <div className={css.bottom_details}>
            <div className={css.circle_container}>
              <FontAwesomeIcon icon={faHandPointRight} className={css.icon} />
            </div>
            <p className={css.list_name}>Hyperledger</p>
          </div>
          <div className={css.bottom_details}>
            <div className={css.circle_container}>
              <FontAwesomeIcon icon={faHandPointRight} className={css.icon} />
            </div>
            <p className={css.list_name}>D-app development</p>
          </div>
          <div className={css.bottom_details}>
            <div className={css.circle_container}>
              <FontAwesomeIcon icon={faHandPointRight} className={css.icon} />
            </div>
            <p className={css.list_name}>Multichain</p>
          </div>
          <div className={css.bottom_details}>
            <div className={css.circle_container}>
              <FontAwesomeIcon icon={faHandPointRight} className={css.icon} />
            </div>
            <p className={css.list_name}>Blockchain architecture</p>
          </div>
          <div className={css.bottom_details}>
            <div className={css.circle_container}>
              <FontAwesomeIcon icon={faHandPointRight} className={css.icon} />
            </div>
            <p className={css.list_name}>Solidity</p>
          </div>
          <div className={css.bottom_details}>
            <div className={css.circle_container}>
              <FontAwesomeIcon icon={faHandPointRight} className={css.icon} />
            </div>
            <p className={css.list_name}>IPFS</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default First;
