import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import css from "./AI.module.css";
import TopSection from "./components/TopSection";
import MiddleSection from "./components/MiddleSection";
import BottomSection from "./components/BottomSection";

const AI = () => {
  return (
    <>
      <Header />
      <TopSection/>
      <MiddleSection/>
      <BottomSection/>
      
      <Footer />
    </>
  );
};

export default AI;
