import css from "./Second.module.css";
import second_bg from "../../assets/second_bg.png";
import code1 from "../../assets/code1.png";
import code2 from "../../assets/code2.png";
import code3 from "../../assets/code3.png";
import code4 from "../../assets/code4.png";
import code5 from "../../assets/code5.png";
import { useNavigate } from "react-router-dom";

import quote_logo from "../../assets/quote_logo.png";
import techno1 from "../../assets/techno1.png";
import frontend from "../../assets/frontend.png";
import backend from "../../assets/backend.png";
import blockchain from "../../assets/blockchain.png";
import mobile from "../../assets/mobile.png";
import devops from "../../assets/devops.png";
import rocket from "../../assets/rocket.png";
import hands from "../../assets/hands.png";
import service_logo from "../../assets/service_logo.png";
import system_logo from "../../assets/system_logo.png";
import work from "../../assets/work.png";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Second = () => {
  const navigate = useNavigate();

  return (
    // <div
    //   className={css.second}
    //   style={{ backgroundImage: `url(${second_bg})` }}
    // >
    //   <div className={css.second_top}>
    //     <img
    //       src={code1}
    //       alt="Cybersecurity Services coding pic"
    //       className={css.code_img1}
    //     />
    //     <img
    //       src={code2}
    //       alt="Cybersecurity Services coding pic"
    //       className={css.code_img2}
    //     />
    //     <img
    //       src={code3}
    //       alt="Cybersecurity Services coding pic"
    //       className={css.code_img3}
    //     />
    //     <img
    //       src={code4}
    //       alt="Cybersecurity Services coding pic"
    //       className={css.code_img4}
    //     />
    //     <img
    //       src={code5}
    //       alt="Cybersecurity Services coding pic"
    //       className={css.code_img5}
    //     />
    //   </div>
    //   <div className={css.second_middle}>
    //     <h2 className={css.middle_heading}>FIELDS WE COVER</h2>
    //     <p className={css.middle_para}>
    //       A reputable supplier of complete cybersecurity services, blockchain
    //       services, and IT solutions, seiaSecure IT Solutions assists companies
    //       in safeguarding their digital assets, utilising the advantages of
    //       blockchain technology, and streamlining their IT infrastructure for
    //       long-term success and growth.
    //     </p>
    //     <div className={css.line}></div>
    //   </div>
    //   <div className={css.second_bottom}>
    //     <div className={css.quote_box}>
    //       <div className={css.img_box}>
    //         <img src={quote_logo} alt="" className={css.quote_img} />
    //       </div>
    //       <p className={css._para}>Financial Services</p>
    //     </div>
    //     <div className={css.quote_box}>
    //       <div className={css.img_box}>
    //         <img src={quote_logo} alt="" className={css.quote_img} />
    //       </div>
    //       <p className={css._para}>Healthcare</p>
    //     </div>
    //     <div className={css.quote_box}>
    //       <div className={css.img_box}>
    //         <img src={quote_logo} alt="" className={css.quote_img} />
    //       </div>
    //       <p className={css._para}>Government Facilities</p>
    //     </div>
    //     <div className={css.quote_box}>
    //       <div className={css.img_box}>
    //         <img src={quote_logo} alt="" className={css.quote_img} />
    //       </div>
    //       <p className={css._para}>Retail</p>
    //     </div>
    //   </div>
    // </div>
    <div className={css.technology}>
      <div className={css.techno_top}>
        <div
          style={{
            backgroundImage: `url(${techno1})`,
            backgroundSize: "cover",
            filter: "blur(7.5px)",
          }}
          className={css.techno_top_img}
        ></div>
        <div className={css.techno_top_texts}>
          <h2 className={css.techno_heading}>TECHNOLOGY STACK</h2>
          <p className={css.top_para1}>
            We always work with latest technologies
          </p>
          {/* <p className={css.top_para2}>LEARN MORE</p> */}
        </div>
      </div>

      <div className={css.techno_middle}>
        <div className={css.middle_cards}>
          <div className={css.middle_card}>
            <img
              className={css.middle_img}
              src={frontend}
              alt="Nixonbit Audit"
            />
            <p className={css.middle_heading}>Front End Technologies</p>
            <p className={css.middle_para}>
              html | javascript | css | react | next js | jquery
            </p>
          </div>
          <div className={css.middle_card}>
            <img
              className={css.middle_img}
              src={backend}
              alt="Nixonbit Audit"
            />
            <p className={css.middle_heading}>Backend Technologies</p>
            <p className={css.middle_para}>
              node js | java | aws | redis | mongo DB | postgres | kafka
            </p>
          </div>
          <div className={css.middle_card}>
            <img className={css.middle_img} src={mobile} alt="Nixonbit Audit" />
            <p className={css.middle_heading}>Mobile App Development</p>
            <p className={css.middle_para}>
              react native | flutter |ios | android | cross platform
            </p>
          </div>
          <div className={css.middle_card}>
            <img className={css.middle_img} src={devops} alt="Nixonbit Audit" />
            <p className={css.middle_heading}>DevOps</p>
            <p className={css.middle_para}>
              aws | azure | sentry | kubernetes | jenkins | sonarqube | helm
            </p>
          </div>
          <div className={css.middle_card}>
            <img
              className={css.middle_img}
              src={blockchain}
              alt="Nixonbit Audit"
            />
            <p className={css.middle_heading}>Blockchain</p>
            <p className={css.middle_para}>
              solidity | gith | mist | ganache | remix | truffle | metamask
            </p>
          </div>
        </div>
      </div>
      <div className={css.techno_bottom}>
        <div className={css.bottom_left}>
          <div className={css.left_card}>
            <img src={rocket} alt="Nixonbit Audit" className={css.left_img1} />
            <h1 className={css.left_card_heading}>3+</h1>
            <p className={css.left_para}>Year In Business</p>
          </div>
          <div className={css.left_card}>
            <img src={hands} alt="Nixonbit Audit" className={css.left_img2} />
            <h1 className={css.left_card_heading}>20+</h1>
            <p className={css.left_para}>Happy Clients</p>
          </div>
          <div className={css.left_card}>
            <img
              src={service_logo}
              alt="Nixonbit Audit"
              className={css.left_img2}
            />
            <h1 className={css.left_card_heading}>24/7</h1>
            <p className={css.left_para}>Support Available</p>
          </div>
          <div className={css.left_card}>
            <img
              src={system_logo}
              alt="Nixonbit Audit"
              className={css.left_img3}
            />
            <h1 className={css.left_card_heading}>25+</h1>
            <p className={css.left_para}>Project Done</p>
          </div>
        </div>
        <div className={css.bottom_right}>
          <div className={css.right_left}>
            <h3 className={css.right_heading}>Hire A Dedicated Developer</h3>
            <p className={css.right_para}>
              Hire a developer according to your needs
            </p>
            <div className={css.arrow_box}>
              <FontAwesomeIcon
                icon={faArrowRight}
                className={css.arrow}
                onClick={() => {
                  navigate("/contact-us");
                }}
              />
            </div>{" "}
          </div>
          <div className={css.right_right}>
            <img
              src={work}
              alt="Nixonbit Audit"
              className={css.bottom_right_img}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
