import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import css from "./PortFolio.module.css";
import dcred from "../../assets/dcred.jpg";
import zepta from "../../assets/zepta.jpg";
import busi from "../../assets/businace.jpg";
import mgcu from "../../assets/mgcu.jpg";
import rbryp from "../../assets/rbryp.jpg";
import abhyaas from "../../assets/abhyaasiit.jpg";

const Portfolio = () => {
  return (
    <>
      <Header />

      <>
        <div className={css.products}>
          <h1 className={css.product_heading}>OUR PORTFOLIO</h1>
          <div className={css.products_container}>
            <div className={css.single_product}>
              <div className={css.img_box}>
                <img
                  src={zepta}
                  alt="Nixonbit Blockchain"
                  className={css.product_img}
                />
              </div>
              <div className={css.product_details}>
                <h2 className={css.prod_name}>zeptagram</h2>
                <p className={css.prod_desc}>
                  Description: Zeptagram is a Blockchain-based Trading platform
                  where Music IP-Rights Owners can Tokenize their asset.
                </p>
                <div className={css.prod_btns}>
                  <button className={css.prod_btn}>UI/UX DESIGN</button>
                  <button className={css.prod_btn}>mobile development</button>
                  <button className={css.prod_btn}>web app development</button>
                </div>
              </div>
            </div>
            <div className={css.single_product}>
              <div className={css.img_box}>
                {/* <img
                  src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara5.png"
                  alt="Nixonbit Blockchain"
                  className={css.product_img}
                /> */}
              </div>
              <div className={css.product_details}>
                <h2 className={css.prod_name}>CHAMPARAN</h2>
                <p className={css.prod_desc}>
                  Description: Champaran Sanskritik mahotsav samiti is an event
                  platform for most awaited art and cultural festival
                </p>
                <div className={css.prod_btns}>
                  <button className={css.prod_btn}>UI/UX DESIGN</button>
                  <button className={css.prod_btn}>devops</button>
                  <button className={css.prod_btn}>web app development</button>
                </div>
              </div>
            </div>
            <div className={css.single_product}>
              <div className={css.img_box}>
                <img
                  src={mgcu}
                  alt="Nixonbit Blockchain"
                  className={css.product_img}
                />
              </div>
              <div className={css.product_details}>
                <h2 className={css.prod_name}>mgcu</h2>
                <p className={css.prod_desc}>
                  Description: Mahatma Gandhi Central University, Bihar,
                  organizes a national econference on the theme “Industry 4.0 &
                  Society 5.0: A Journey from Industrial Revolutions to Societal
                  Eminence”
                </p>
                <div className={css.prod_btns}>
                  <button className={css.prod_btn}>UI/UX DESIGN</button>
                  <button className={css.prod_btn}>devops</button>
                  <button className={css.prod_btn}>web app development</button>
                </div>
              </div>
            </div>
            <div className={css.single_product}>
              <div className={css.img_box}>
                {/* <img
                  src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara6.png"
                  alt="Nixonbit Blockchain"
                  className={css.product_img}
                /> */}
              </div>
              <div className={css.product_details}>
                <h2 className={css.prod_name}>tartarus</h2>
                <p className={css.prod_desc}>
                  Description:Tartarus is a Next-Gen Decentralized Launchpad
                  built for Fantom Ecosystem
                </p>
                <div className={css.prod_btns}>
                  <button className={css.prod_btn}>UI/UX DESIGN</button>
                  <button className={css.prod_btn}>devops</button>
                  <button className={css.prod_btn}>mobile development</button>
                  <button className={css.prod_btn}>WEB#.JS</button>
                </div>
              </div>
            </div>
            {/* <div className={css.single_product}>
              <div className={css.img_box}>
                <img src="" alt="" className={css.product_img} />
              </div>
              <div className={css.product_details}>
                <h2 className={css.prod_name}>starter</h2>
                <p className={css.prod_desc}>
                  Description:Harmony is a unique blockchain platform designed
                  to facilitate the creation and use of decentralized
                  applications (DApps).
                </p>
                <div className={css.prod_btns}>
                  <button className={css.prod_btn}>web3.js</button>
                  <button className={css.prod_btn}>web app development</button>
                </div>
              </div>
            </div> */}
            <div className={css.single_product}>
              <div className={css.img_box}>
                <img
                  src={dcred}
                  alt="Nixonbit Blockchain"
                  className={css.product_img}
                />
              </div>
              <div className={css.product_details}>
                <h2 className={css.prod_name}>dcred</h2>
                <p className={css.prod_desc}>
                  Description: Dcred is an uncollateralized lending platform
                  that uses Aave credit delegation mechanism to fund loans to
                  the potential borrowers. DNFT
                </p>
                <div className={css.prod_btns}>
                  <button className={css.prod_btn}>web3.js</button>
                  <button className={css.prod_btn}>DNFT</button>
                  <button className={css.prod_btn}>web app development</button>
                </div>
              </div>
            </div>

            <div className={css.single_product}>
              <div className={css.img_box}>
                <img
                  src={rbryp}
                  alt="Nixonbit Blockchain"
                  className={css.product_img}
                />
              </div>
              <div className={css.product_details}>
                <h2 className={css.prod_name}>
                  Ram Bhawan Ram Yamuna Prasad Groups
                </h2>
                <p className={css.prod_desc}>
                  Description: At Ram Bhawan Ram Yamuna Prasad, we offer a
                  diverse range of services and products nationwide. Our
                  commitment spans hospitality, education, automobiles, fashion,
                  and dining experiences, all aimed at enhancing lives and
                  serving our community with excellence in every endeavor.
                </p>
                <div className={css.prod_btns}>
                  <button className={css.prod_btn}>UI/UX DESIGN</button>
                  <button className={css.prod_btn}>web app development</button>
                </div>
              </div>
            </div>

            <div className={css.single_product}>
              <div className={css.img_box}>
                <img
                  src={abhyaas}
                  alt="Nixonbit Blockchain"
                  className={css.product_img}
                />
              </div>
              <div className={css.product_details}>
                <h2 className={css.prod_name}>ABHYAAS IIT</h2>
                <p className={css.prod_desc}>
                  Description: Empower your IIT JEE & NEET exam preparation at
                  AbhyaasIIT in Motihari. IITian Mentors, personalized guidance,
                  and a pathway to success. Join us today!
                </p>
                <div className={css.prod_btns}>
                  <button className={css.prod_btn}>UI/UX DESIGN</button>
                  <button className={css.prod_btn}>devops</button>
                  <button className={css.prod_btn}>web app development</button>
                </div>
              </div>
            </div>

            <div className={css.single_product}>
              <div className={css.img_box}>
                <img
                  src={busi}
                  alt="Nixonbit Blockchain"
                  className={css.product_img}
                />
              </div>
              <div className={css.product_details}>
                <h2 className={css.prod_name}>Businace</h2>
                <p className={css.prod_desc}>
                  Description: Businace: Your Comprehensive Product Management
                  Solution. Businace is a powerful app designed to streamline
                  product tracking for companies of all sizes. Effortlessly
                  manage inventory, check product availability, and track item
                  locations in real-time. With intuitive features and seamless
                  integration, Business empowers you to optimize operations and
                  ensure efficient product management, all at your fingertips.
                </p>
                <div className={css.prod_btns}>
                  <button className={css.prod_btn}>UI/UX DESIGN</button>
                  <button className={css.prod_btn}>devops</button>
                  <button className={css.prod_btn}>web app development</button>
                  <button className={css.prod_btn}>
                    mobile app development
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <Footer />
    </>
  );
};

export default Portfolio;
