// import AboutUs from "./pages/about-us/AboutUs";
import Audit from "./pages/audit/Audit";
import Blockchain from "./pages/blockchain/Blockchain";
import SoftwareDevelopment from "./pages/softwareDevelopment/SoftwareDevelopment";
import DigitalMarketing from "./pages/digitalMarketing/DigitalMarketing";
import AI from "./pages/ai/AI";
import WebAppDevelopment from "./pages/webAppDevelopment/webappdevelopment";
import MobAppDevelopment from "./pages/mobileAppDevelopment/mobileappdevelopment";
import MachineLearning from "./pages/machineLearning/machinelearning";
import CloudService from "./pages/cloudService/cloudservice";
import CyberSecurity from "./pages/cyberSecurity/CyberSecurity";
import DataScience from "./pages/dataScience/datascience";
import BotService from "./pages/botService/botservice";

import Home from "./pages/home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Extra from "./pages/extras/Extra";
import Products from "./pages/products/Products";
import Portfolio from "./pages/portfolio/Portfolio";
import Services from "./pages/services/Services";
import TeamExperts from "./pages/teamExperts/TeamExperts";
import Contact from "./pages/contact/Contact";
import ForensicTools from "./pages/forensic/ForensicTools";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/about-us" element={<AboutUs />} /> */}
          <Route path="/services" element={<Services />} />
          <Route path="/products" element={<Products />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/team-experts" element={<TeamExperts />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blockchain" element={<Blockchain />} />
          <Route path="/audit" element={<Audit />} />
          <Route
            path="/software-development"
            element={<SoftwareDevelopment />}
          />
          <Route path="/cybersecurity" element={<CyberSecurity />} />
          <Route path="/machine-learning" element={<MachineLearning />} />
          <Route path="/web-app-development" element={<WebAppDevelopment />} />
          <Route
            path="/mobile-app-development"
            element={<MobAppDevelopment />}
          />
          <Route path="/cloud-service" element={<CloudService />} />
          <Route path="/bot-service" element={<BotService />} />
          <Route path="/data-science" element={<DataScience />} />
          <Route path="/ai-chat-gpt" element={<AI />} />
          <Route path="/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/forensic Tools" element={<ForensicTools />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
