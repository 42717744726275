import React from "react";
import css from "./BottomSection.module.css";
import aibottom1 from "../../../assets/aibottom1.png";
import aibottom2 from "../../../assets/aibottom2.png";
import aibottom3 from "../../../assets/aibottom3.png";
import aibottom4 from "../../../assets/aibottom4.png";
import aibottom5 from "../../../assets/aibottom5.png";

const BottomSection = () => {
  return (
    <>
      <div className={css.maindiv}>
        <div className={css.div1}>
          <p1 className={css.para1}>WHY CHOOSE SeiaSecure:</p1>
          <div className={css.div2}>
            <div className={css.div3}>
              <div
                className={css.div4}
                style={{
                  backgroundImage: `url(${aibottom1})`,
                  backgroundSize: "cover",
                }}
              ></div>

              <div className={css.div5}>
                <p1 className={css.para2}>AI Expertise</p1>
              </div>

              <div className={css.div6}>
                <p1 className={css.para3}>
                  Our team comprises skilled professionals with extensive
                  experience in AI/ML technologies, ensuring the delivery of
                  high-quality solutions tailored to your specific requirements.
                </p1>
              </div>
            </div>

            <div className={css.div3}>
              <div
                className={css.div14}
                style={{
                  backgroundImage: `url(${aibottom2})`,
                  backgroundSize: "cover",
                }}
              ></div>

              <div className={css.div5}>
                <p1 className={css.para2}>Innovation</p1>
              </div>

              <div className={css.div6}>
                <p1 className={css.para3}>
                  We stay at the forefront of technological advancements,
                  continually exploring new techniques and methodologies to
                  enhance our offerings and deliver innovative solutions.
                </p1>
              </div>
            </div>

            <div className={css.div3}>
              <div
                className={css.div4}
                style={{
                  backgroundImage: `url(${aibottom3})`,
                  backgroundSize: "cover",
                }}
              ></div>

              <div className={css.div5}>
                <p1 className={css.para2}>Reliability</p1>
              </div>

              <div className={css.div6}>
                <p1 className={css.para3}>
                  With a proven track record of successful projects and
                  satisfied clients, SeiaSecure is a trusted partner for
                  businesses seeking reliable AI/ML solutions.
                </p1>
              </div>
            </div>

            <div className={css.div3}>
              <div
                className={css.div14}
                style={{
                  backgroundImage: `url(${aibottom4})`,
                  backgroundSize: "cover",
                }}
              ></div>

              <div className={css.div5}>
                <p1 className={css.para2}>Scalability</p1>
              </div>

              <div className={css.div6}>
                <p1 className={css.para3}>
                  Our solutions are designed to scale with your business,
                  ensuring flexibility and adaptability to accommodate future
                  growth and evolving needs.
                </p1>
              </div>
            </div>

            <div className={css.div3}>
              <div
                className={css.div4}
                style={{
                  backgroundImage: `url(${aibottom5})`,
                  backgroundSize: "cover",
                }}
              ></div>

              <div className={css.div5}>
                <p1 className={css.para2}>ROI Focus</p1>
              </div>

              <div className={css.div6}>
                <p1 className={css.para3}>
                  We prioritize delivering measurable returns on your AI
                  investment.
                </p1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BottomSection;
