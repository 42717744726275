import React from "react";
import css from "./TopSection.module.css";
import dmhero from "../../../assets/dmhero.png";

const TopSection = () => {
  return (
    <>
      <div className={css.maindiv}>
        <div className={css.div1}>
          <div className={css.headerdiv}>
            <p1 className={css.para1}>ELEVATE YOUR ONLINE PRESENCE</p1>
          </div>
          <div className={css.paradiv}>
            <p1 className={css.para2}>
              Welcome to SeiaSecure, where we specialize in transforming
              businesses into digital success stories. In today's fast-paced
              digital landscape, having a strong online presence is vital for
              reaching and engaging your target audience. Discover how our
              digital marketing expertise can help you thrive in the digital
              world.
            </p1>
          </div>
        </div>
        <img
          src={dmhero}
          className={css.image1}
          alt="Nixonbit Digital marketing"
        ></img>
      </div>
    </>
  );
};

export default TopSection;
