import css from "./Awards.module.css";
import { useNavigate } from "react-router-dom";
import awards1 from "../../assets/awards1.png";
import awards2 from "../../assets/awards2.png";

const Awards = () => {
  const navigate = useNavigate();
  return (
    <div className={css.awards}>
      <div className={css.awards_top}>
        <h2 className={css.top_title}>AWARDS</h2>
        <div className={css.awards_imgs}>
          <img src={awards1} alt="Nixonbit Audit" className={css.award_img} />
          <img
            src={awards2}
            alt="Nixonbit Digital marketing"
            className={css.award_img}
          />
        </div>
      </div>
      <div className={css.awards_bottom}>
        <h2 className={css.bottom_heading1}>LET'S WORK TOGETHER</h2>
        <h2 className={css.bottom_heading2}>
          We Love To Listen To Your Requirements
        </h2>
        <button
          className={css.bottom_btn}
          onClick={() => {
            navigate("/contact-us");
          }}
        >
          Estimate Project{" "}
        </button>
        <div className={css.awards_bottom_para}>
          <p className={css.bottom_para1}>Or call us now </p>
          <p className={css.bottom_para1}>+91 93043 45393</p>
        </div>
      </div>
    </div>
  );
};

export default Awards;
