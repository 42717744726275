import css from "./Fourth.module.css";
import fourth_img from "../../assets/fourth_img.png";
import top_dots from "../../assets/top_dots.png";
import bottom_dots from "../../assets/bottom_dots.png";
import { Tabs } from "antd";
import navyLogo from "../../assets/navyLogo.jpg";
import dcred from "../../assets/dcred.jpg";
import cara1 from "../../assets/cara1.jpeg";
import cara2 from "../../assets/cara2.jpeg";
import cara3 from "../../assets/cara3.jpeg";
import cara4 from "../../assets/cara4.jpeg";
import cara5 from "../../assets/cara5.jpeg";
import cara6 from "../../assets/cara6.jpeg";
// import cara7 from "../../assets/cara7.jpeg";
import cara8 from "../../assets/cara8.jpeg";
// import cara9 from "../../assets/cara9.jpeg";

import Accordion from "../accordion/Accordion";
import Services from "../services/Services";
// import Contact from "../../../contact/Contact";
import vishal from "../../assets/vishal.png";
import suneet from "../../assets/suneet.png";
import rakesh from "../../assets/rakesh.png";

const Fourth = () => {
  return (
    <div className={css.our_clients}>
      <h2 className={css.heading}>OUR CLIENTS AND PARTNERS</h2>
      <div className={css.slider}>
        <div className={css.slider_track}>
          {/* first */}
          <div className={css.slide}>
            <img src={cara1} alt="Nixonbit Digital marketing" />
          </div>
          <div className={css.slide}>
            <img src={cara2} alt="Nixonbit Digital marketing" />
          </div>
          <div className={css.slide}>
            <img src={cara3} alt="Nixonbit Digital marketing" />
          </div>
          <div className={css.slide}>
            <img src={cara4} alt="Nixonbit Digital marketing" />
          </div>
          {/* <div className={css.slide}>
            <img src={dcred} alt="Nixonbit Digital marketing" />
          </div> */}
          <div className={css.slide}>
            <img src={cara5} alt="Nixonbit Digital marketing" />
          </div>
          <div className={css.slide}>
            <img src={cara1} alt="Nixonbit Digital marketing" />
          </div>
          <div className={css.slide}>
            <img src={dcred} alt="dcred" className={css.logo_img} />{" "}
          </div>
          <div className={css.slide}>
            <img src={navyLogo} alt="Navy Logo" className={css.logo_img} />
          </div>

          {/* secons */}

          <div className={css.slide}>
            <img src={cara8} alt="Nixonbit Digital marketing" />
          </div>
          {/* <div className={css.slide}>
            <img src={cara8} alt="Nixonbit Digital marketing" />
          </div> */}
          <div className={css.slide}>
            <img src={cara3} alt="Nixonbit Digital marketing" />
          </div>
          <div className={css.slide}>
            <img src={cara4} alt="Nixonbit Digital marketing" />
          </div>
          <div className={css.slide}>
            <img src={cara5} alt="Nixonbit Digital marketing" />
          </div>
          <div className={css.slide}>
            <img src={cara2} alt="Nixonbit Digital marketing" />
          </div>
          <div className={css.slide}>
            <img src={navyLogo} alt="Nixonbit Digital marketing" />
          </div>
          <div className={css.slide}>
            <img
              src="https://dcred.io/assets/img/dcredLogo.svg"
              alt="Nixonbit Digital marketing"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fourth;
