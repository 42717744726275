import { Link, NavLink } from "react-router-dom";
import css from "./Header.module.css";
import { useState } from "react";
import DropDown from "../dropdown/DropDown";

const Header = () => {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);

  const onMouseEnter = () => {
    console.log("mouseenter");
    if (window.innerWidth < 700) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const openDropdown = () => {
    if (window.innerWidth < 700) {
      setDropdown(!dropdown);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 700) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  return (
    <div className={css.header}>
      <Link
        to="/"
        className={css.logo_box}
        style={{ textDecoration: "none", color: "#ffffff" }}
      >
        <h3 className={css.logo}>seiaSecure®</h3>
      </Link>
      <div className={click ? css.mbl_nav_container : css.nav_container}>
        <li className={css.nav_list}>
          <NavLink
            to="/"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            HOME
          </NavLink>
        </li>
        {/* <li className={css.nav_list}>
          <NavLink
            to="/about-us"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
          >
            ABOUT-US
          </NavLink>
        </li> */}
        <li
          className={css.nav_list}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={openDropdown}
        >
          <NavLink
            to="/services"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
          >
            SERVICES+
          </NavLink>
          {dropdown && <DropDown />}
        </li>
        <li className={css.nav_list}>
          <NavLink
            to="/products"
            style={({ isActive }) => ({
              borderBottom: isActive ? "3px solid #f4f4f4" : "",
            })}
          >
            PRODUCTS
          </NavLink>
        </li>{" "}
        <li className={css.nav_list}>
          <NavLink
            to="/portfolio"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
          >
            PORTFOLIO
          </NavLink>
        </li>
        <li className={css.nav_list}>
          <NavLink
            to="/team-experts"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
          >
            TEAM EXPERTS
          </NavLink>
        </li>
        <li className={css.nav_list}>
          <NavLink
            to="/contact"
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
          >
            CONTACT-US
          </NavLink>
        </li>
      </div>
      <div className={css.header_mbl} onClick={handleClick}>
        <i className={click ? "fas fa-times fa-md" : "fas fa-bars fa-md"} />
      </div>
    </div>
  );
};

export default Header;
