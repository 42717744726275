import css from "./Fifth.module.css";
import middle1 from "../../assets/middle1.png";
import middle2 from "../../assets/middle2.png";
import middle3 from "../../assets/middle3.png";
import middle4 from "../../assets/middle4.png";
import bottom1 from "../../assets/bottom1.png";
import bottom2 from "../../assets/bottom2.png";
import bottom3 from "../../assets/bottom3.png";
import bottom4 from "../../assets/bottom4.png";
import bottom5 from "../../assets/bottom5.png";
import bottom6 from "../../assets/bottom6.png";

const Fifth = () => {
  return (
    <div className={css.services}>
      <div className={css.service_top}>
        <h3 className={css.top_heading}>APPLICATIONS OF THAT INFRASTRUCTURE</h3>
        <div className={css.top_cards}>
          <div className={css.top_card}>
            <div className={css.top_card_title}>decentralised apps</div>
            <p className={css.top_card_para}>
              Applications built on open networks enabling financial, social and
              other activities.
            </p>
          </div>
          <div className={css.top_card}>
            <div className={css.top_card_title}>DeFi</div>
            <p className={css.top_card_para}>
              Financial platform that run entirely on code using smart contracts
              on a blockchain.
            </p>
          </div>
          <div className={css.top_card}>
            <div className={css.top_card_title}>(open) digital wallets</div>
            <p className={css.top_card_para}>
              Online “passport” that combines aspects of identity, access and
              ownership for the user.
            </p>
          </div>
          <div className={css.top_card}>
            <div className={css.top_card_title}>Daos</div>
            <p className={css.top_card_para}>
              member-owned communities with a shared bank accont.
            </p>
          </div>
          <div className={css.top_card}>
            <div className={css.top_card_title}>
              tokenisation of real world assets
            </div>
            <p className={css.top_card_para}>
              Digital,universal representation of assets such as property , gold
              and art
            </p>
          </div>
          <div className={css.top_card}>
            <div className={css.top_card_title}>open metaverse</div>
            <p className={css.top_card_para}>
              Digital space where users can live, interact and explore.
            </p>
          </div>
        </div>
      </div>
      <div className={css.service_middle}>
        <h2 className={css.middle_title}>Ecosystem key features</h2>
        <div className={css.middle_cards}>
          <div className={css.middle_card1}>
            <img src={middle1} alt="Nixonbit Blockchain" />
            <p className={css.middle_para_colored}>
              Analyzing matrix between Economy, Indusrty & Company Parameters
              using ML and predictive models.
            </p>
          </div>
          <div className={css.middle_card2}>
            <img src={middle2} alt="Nixonbit Blockchain" />
            <p className={css.middle_para}>
              We provides 3 times affordable portfolio hedging tool than
              existing hedging instruments..
            </p>
          </div>
          <div className={css.middle_card1}>
            <img src={middle3} alt="Nixonbit Blockchain" />
            <p className={css.middle_para_colored}>
              Individual Risk profiling allows issuance, trade, and management
              of decentralized derivative assets.
            </p>
          </div>
          <div className={css.middle_card2}>
            <img src={middle4} alt="Nixonbit Blockchain" />
            <p className={css.middle_para}>
              Create customizable synthetic financial contracts comprising
              multiple assets using blockchain ecosystem
            </p>
          </div>
        </div>
      </div>
      <div className={css.service_bottom}>
        <h2 className={css.bottom_title}>Our Services Catalog</h2>
        <p className={css.bottom_title2}>
          You have an idea, but need help with development, setup and
          maintenance. From creating strategy to full implementation. We do it
          all.
        </p>
        <div className={css.bottom_cards}>
          <div className={css.bottom_card}>
            <img
              src={bottom1}
              alt="Nixonbit Blockchain"
              className={css.bottom_card_img}
            />
            <p className={css.bottom_card_para}>Web App Development</p>
          </div>
          <div className={css.bottom_card}>
            <img
              src={bottom2}
              alt="Nixonbit Blockchain"
              className={css.bottom_card_img}
            />
            <p className={css.bottom_card_para}>Mobile App Development</p>
          </div>
          <div className={css.bottom_card}>
            <img
              src={bottom3}
              alt="Nixonbit Blockchain"
              className={css.bottom_card_img}
            />
            <p className={css.bottom_card_para}>Mobile App Development</p>
          </div>
          <div className={css.bottom_card}>
            <img
              src={bottom4}
              alt="Nixonbit Blockchain"
              className={css.bottom_card_img}
            />
            <p className={css.bottom_card_para}>DevOps</p>
          </div>
          <div className={css.bottom_card}>
            <img
              src={bottom5}
              alt="Nixonbit Blockchain"
              className={css.bottom_card_img}
            />
            <p className={css.bottom_card_para}>Cloud Computing</p>
          </div>
          <div className={css.bottom_card}>
            <img
              src={bottom6}
              alt="Nixonbit Blockchain"
              className={css.bottom_card_img}
            />
            <p className={css.bottom_card_para}>IT Support And Maintenance</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fifth;
