import React from "react";
import css from "./CyberSecHero.module.css";
import csimg1 from "../../../assets/csimg1.png";
import ellipse from "../../../assets/ellipse.png";

const CyberSecHero = () => {
  return (
    <>
      <div className={css.maindiv}>
        <div className={css.div1}>
          <p1 className={css.para1}>
            SECURING YOUR DIGITAL WORLD WITH NIXONBIT CYBERSECURITY
          </p1>
          <br></br>
          <br></br>
          <p1 className={css.para2}>
            At NixonBit, we understand that the digital landscape is fraught
            with challenges, from ever-evolving cyber threats to the complex
            regulatory environment. That's why we're here—to protect your
            digital world with cutting-edge cybersecurity solutions. With a
            focus on innovation, expertise, and a commitment to your security,
            we offer a comprehensive range of cybersecurity services tailored to
            your unique needs.
          </p1>
        </div>
        <div className={css.div2}>
          <img src={csimg1} className={css.img1} alt="Nixonbit AI"></img>
        </div>
      </div>

      <div className={css.maindiv2}>
        <div className={css.div3}>
          <p1 className={css.para3}>OUR CYBERSECURITY SERVICES</p1>
        </div>
        <div className={css.div9}>
          <div className={css.div4}>
            <div className={css.div5}>
              <div className={css.div6}>
                <img src={ellipse} className={css.elli} alt="Nixonbit AI"></img>
                <div className={css.div7}>
                  <p1 className={css.para4}>THREAT DETECTION AND RESPONSE:</p1>
                  <div className={css.div8}>
                    <ul className={css.list}>
                      <li>Real-time threat monitoring and detection</li>
                      <li>Rapid incident response to minimize damage</li>
                      <li>
                        Proactive threat hunting to stay ahead of attackers
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className={css.div5}>
              <div className={css.div6}>
                <img src={ellipse} className={css.elli} alt="Nixonbit AI"></img>
                <div className={css.div7}>
                  <p1 className={css.para4}>VULNERABILITY ASSESSMENTS:</p1>
                  <div className={css.div8}>
                    <ul className={css.list}>
                      <li>Real-time threat monitoring and detection</li>
                      <li>Rapid incident response to minimize damage</li>
                      <li>
                        Proactive threat hunting to stay ahead of attackers
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className={css.div5}>
              <div className={css.div6}>
                <img src={ellipse} className={css.elli} alt="Nixonbit AI"></img>
                <div className={css.div7}>
                  <p1 className={css.para4}>SECURITY AUDITS:</p1>
                  <div className={css.div8}>
                    <ul className={css.list}>
                      <li>Real-time threat monitoring and detection</li>
                      <li>Rapid incident response to minimize damage</li>
                      <li>
                        Proactive threat hunting to stay ahead of attackers
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={css.div4}>
            <div className={css.div5}>
              <div className={css.div6}>
                <img src={ellipse} className={css.elli} alt="Nixonbit AI"></img>
                <div className={css.div7}>
                  <p1 className={css.para4}>ENDPOINT SECURITY:</p1>
                  <div className={css.div8}>
                    <ul className={css.list}>
                      <li>Real-time threat monitoring and detection</li>
                      <li>Rapid incident response to minimize damage</li>
                      <li>
                        Proactive threat hunting to stay ahead of attackers
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className={css.div5}>
              <div className={css.div6}>
                <img src={ellipse} className={css.elli} alt="Nixonbit AI"></img>
                <div className={css.div7}>
                  <p1 className={css.para4}>
                    IDENTITY AND ACCESS MANAGEMENT(IMA):
                  </p1>
                  <div className={css.div8}>
                    <ul className={css.list}>
                      <li>Real-time threat monitoring and detection</li>
                      <li>Rapid incident response to minimize damage</li>
                      <li>
                        Proactive threat hunting to stay ahead of attackers
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className={css.div5}>
              <div className={css.div6}>
                <img src={ellipse} className={css.elli} alt="Nixonbit AI"></img>
                <div className={css.div7}>
                  <p1 className={css.para4}>CLOUD SECURITY:</p1>
                  <div className={css.div8}>
                    <ul className={css.list}>
                      <li>Real-time threat monitoring and detection</li>
                      <li>Rapid incident response to minimize damage</li>
                      <li>
                        Proactive threat hunting to stay ahead of attackers
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CyberSecHero;
