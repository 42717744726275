import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import CyberSecHero from "./components/CyberSecHero";
import CyberSecFooter from "./components/CyberSecFooter";

const CyberSecurity = () => {
  return (
    <>
      <Header />
      <CyberSecHero />
      <CyberSecFooter />
      <Footer />
    </>
  );
};

export default CyberSecurity;
