import React from "react";
import css from "./CyberSecFooter.module.css";
import sq1 from "../../../assets/sq1.png";
import sq2 from "../../../assets/sq2.png";
import sq3 from "../../../assets/sq3.png";
import sq4 from "../../../assets/sq4.png";
import sq5 from "../../../assets/sq5.png";

const CyberSecFooter = () => {
  return (
    <>
      <div className={css.maindiv}>
        <div className={css.div1}>
          <p1 className={css.para1}>WHY CHOOSE SeiaSecure:</p1>
          <div className={css.div2}>
            <div className={css.div3}>
              <div
                className={css.div4}
                style={{
                  backgroundImage: `url(${sq1})`,
                  backgroundSize: "cover",
                }}
              ></div>

              <div className={css.div5}>
                <p1 className={css.para2}>Expert Team</p1>
              </div>

              <div className={css.div6}>
                <p1 className={css.para3}>
                  Our cybersecurity experts are certified and experienced in
                  protecting businesses across industries.
                </p1>
              </div>
            </div>

            <div className={css.div3}>
              <div
                className={css.div14}
                style={{
                  backgroundImage: `url(${sq2})`,
                  backgroundSize: "cover",
                }}
              ></div>

              <div className={css.div5}>
                <p1 className={css.para2}>Custom Solutions</p1>
              </div>

              <div className={css.div6}>
                <p1 className={css.para3}>
                  We tailor our services to meet your specific security needs
                  and compliance requirements.
                </p1>
              </div>
            </div>

            <div className={css.div3}>
              <div
                className={css.div4}
                style={{
                  backgroundImage: `url(${sq3})`,
                  backgroundSize: "cover",
                }}
              ></div>

              <div className={css.div5}>
                <p1 className={css.para2}>24/7 Monitoring</p1>
              </div>

              <div className={css.div6}>
                <p1 className={css.para3}>
                  We provide continuous threat monitoring and incident response
                  to ensure your digital assets are always protected.
                </p1>
              </div>
            </div>

            <div className={css.div3}>
              <div
                className={css.div14}
                style={{
                  backgroundImage: `url(${sq4})`,
                  backgroundSize: "cover",
                }}
              ></div>

              <div className={css.div5}>
                <p1 className={css.para2}>Cutting-Edge Technology</p1>
              </div>

              <div className={css.div6}>
                <p1 className={css.para3}>
                  We leverage the latest in cybersecurity technology, including
                  AI and machine learning, to stay ahead of evolving threats.
                </p1>
              </div>
            </div>

            <div className={css.div3}>
              <div
                className={css.div4}
                style={{
                  backgroundImage: `url(${sq5})`,
                  backgroundSize: "cover",
                }}
              ></div>

              <div className={css.div5}>
                <p1 className={css.para2}>Compliance Focus</p1>
              </div>

              <div className={css.div6}>
                <p1 className={css.para3}>
                  We help you navigate complex regulatory landscapes and
                  maintain compliance with industry standards.
                </p1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CyberSecFooter;
