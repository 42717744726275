import Fifth from "../../components/fifth/Fifth";
import First from "../../components/first/First";
import Footer from "../../components/footer/Footer";
import Fourth from "../../components/fourth/Fourth";
import Header from "../../components/header/Header";
import Hero from "../../components/hero/Hero";
import Second from "../../components/second/Second";
import Third from "../../components/third/Third";
import Awards from "../../components/awards/Awards";

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <First />
      <Second />
      <Third />
      <Fourth />
      <Fifth />
      <Awards />
      <Footer />
    </>
  );
};

export default Home;
