import React from "react";
import css from "./MiddleSection.module.css";
import csimg1 from "../../../assets/csimg1.png";
import ellipse from "../../../assets/ellipse.png";

const MiddleSection = () => {
  return (
    <>
      <div className={css.maindiv2}>
        <div className={css.div3}>
          <p1 className={css.para3}>OUR SOFTWARE-DEVELOPMENT SERVICES</p1>
        </div>
        <div className={css.div9}>
          <div className={css.div4}>
            <div className={css.div5}>
              <div className={css.div6}>
                <div className={css.div7}>
                  <p1 className={css.para4}>CUSTOM SOFTWARE DEVELOPMENT:</p1>
                  <div className={css.div8}>
                    <ul className={css.list}>
                      <li>
                        Bespoke software solutions tailored to your unique needs
                      </li>
                      <li>
                        Agile development methodologies for flexibility and
                        adaptability
                      </li>
                      <li>
                        Full-stack development expertise from frontend to
                        backend
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className={css.div5}>
              <div className={css.div6}>
                <div className={css.div7}>
                  <p1 className={css.para4}>WEB APPLICATION DEVELOPMENT:</p1>
                  <div className={css.div8}>
                    <ul className={css.list}>
                      <li>Responsive and user-friendly web applications</li>
                      <li>
                        E-commerce platforms, portals, and content management
                        systems
                      </li>
                      <li>Cross-browser and cross-device compatibility</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className={css.div5}>
              <div className={css.div6}>
                <div className={css.div7}>
                  <p1 className={css.para4}>MOBILE APP DEVELOPMENT:</p1>
                  <div className={css.div8}>
                    <ul className={css.list}>
                      <li>Native and cross-platform mobile app development</li>
                      <li>iOS, Android, and hybrid app solutions</li>
                      <li>User-centric design and seamless user experiences</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={css.div4}>
            <div className={css.div5}>
              <div className={css.div6}>
                <div className={css.div7}>
                  <p1 className={css.para4}>AI SOLUTIONS:</p1>
                  <div className={css.div8}>
                    <ul className={css.list}>
                      <li>Chat-GPT development</li>
                      <li>AI solutions and process automation</li>
                      <li>Data analysis ana insights</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className={css.div5}>
              <div className={css.div6}>
                <div className={css.div7}>
                  <p1 className={css.para4}>CLOUD-BASED SOLUTIONS:</p1>
                  <div className={css.div8}>
                    <ul className={css.list}>
                      <li>Cloud architecture design and deployment</li>
                      <li>Scalable and cost-effective cloud solutions</li>
                      <li>Cloud migration and management services</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className={css.div5}>
              <div className={css.div6}>
                <div className={css.div7}>
                  <p1 className={css.para4}>IOT AND EMBEDDED SYSTEMS:</p1>
                  <div className={css.div8}>
                    <ul className={css.list}>
                      <li>IoT device and sensor integration</li>
                      <li>Real-time data collection and analysis</li>
                      <li>IoT application development for smart solutions</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MiddleSection;
