import css from "./Hero.module.css";
import hero1 from "../../assets/hero1.png";
import hero_img from "../../assets/hero_img.png";
import big_btn from "../../assets/big_btn.png";

const Hero = () => {
  return (
    <div
      className={css.hero}
      id="/"
      style={{
        backgroundImage: `url(${hero1})`,
      }}
    >
      <h1 className={css.hero_heading}>
        "We Strive to Revolutionize the World Through Cutting-Edge Technology."{" "}
      </h1>
      <p className={css.hero_para}>
        "We use cutting-edge technology to transform industries and improve
        lives. By pushing boundaries and redefining standards, we empower
        individuals and businesses to thrive in the digital era, aiming to drive
        progress and make a lasting global impact through relentless
        innovation."{" "}
      </p>
      <div className={css.hero_btns}>
        <button className={css.hero_btn1}>CONTACT US</button>
        <button className={css.hero_btn2}>FREE DEMO</button>
      </div>
      <div className={css.big_btn}>
        <img src={big_btn} alt="" className={css._big_btn_img} />
        <p className={css.btn_para}>WHAT WE DO</p>
      </div>
    </div>
  );
};

export default Hero;
