import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import css from "./Blockchain.module.css";
import block_chain_img from "../../assets/block_chain_img.png";
import Rectangle1 from "../../assets/Rectangle1.png";
import Rectangle2 from "../../assets/Rectangle2.png";
import Rectangle3 from "../../assets/Rectangle3.png";
import Rectangle4 from "../../assets/Rectangle4.png";
import Rectangle5 from "../../assets/Rectangle5.png";

const Blockchain = () => {
  return (
    <>
      <Header />
      <>
        <div className={css.content}>
          <div className={css.content_top}>
            <div className={css.content_top_headings}>
              <h2 className={css.content_top_heading}>blockchain solutions</h2>
              <p className={css.content_top_para}>
                We have elected to put our money and faith in a mathematical
                framework that is free of politics and human error.
              </p>
            </div>
            <div className={css.content_top_box}>
              <div className={css.content_top_box_left_cir}>
                <img
                  src={block_chain_img}
                  className={css.block_chain_img}
                  alt="Nixonbit Blockchain"
                />
              </div>
              <div className={css.content_top_box_right}>
                <p className={css.content_top_box_para}>
                  Our team of experts has the experience and know-how to develop
                  a blockchain solution that is tailored to your business needs.
                  We can help you create a blockchain application that is
                  secure, scalable, and easy to use
                </p>
              </div>
            </div>
            <div className={css.content_top_box}>
              <div className={css.content_top_box_left}>
                <h3 className={css.content_top_box_heading}>
                  private blockchain
                </h3>
                <p className={css.content_top_box_para}>
                  Private blockchain networks are permissioned, meaning that
                  access to the network is restricted to certain users. Private
                  blockchains are often used for enterprise applications.
                  Advantages of private blockchain networks include: Increased
                  security: Private blockchains are more secure than public
                  blockchains because they are not accessible to everyone.
                  Private blockchains are more secure than public blockchains
                  because they are not accessible to everyone.{" "}
                </p>
              </div>
              <div className={css.content_top_box_right}>
                <img
                  src={Rectangle1}
                  alt="Nixonbit Blockchain"
                  className={css.block_chain_img}
                />
              </div>
            </div>
            <div className={css.content_top_box}>
              <div className={css.content_top_box_left}>
                <img
                  src={Rectangle2}
                  alt="Nixonbit Blockchain"
                  className={css.block_chain_img}
                />
              </div>
              <div className={css.content_top_box_right}>
                <h3 className={css.content_top_box_heading}>
                  decentralised apps
                </h3>
                <p className={css.content_top_box_para}>
                  Decentralised apps are applications that use blockchain
                  technology to function. They are able to run on a network of
                  computers rather than on a single server, meaning that they
                  are not reliant on a single entity to function. Because of
                  this, they are often seen as being more secure and resilient
                  than traditional apps.
                </p>
              </div>
            </div>
            <div className={css.content_top_box}>
              <div className={css.content_top_box_left}>
                <h3 className={css.content_top_box_heading}>smart contracts</h3>
                <p className={css.content_top_box_para}>
                  Smart contracts are self-executing transactions with the terms
                  of the agreement between buyer and seller being directly
                  written into lines of code. Once the conditions are met, the
                  contract is automatically executed. The advantage of smart
                  contracts is that they can theoretically reduce the need for
                  trust between parties, because the contract can only be
                  executed if the conditions are met. This could potentially
                  reduce costs associated with things like dispute resolution.
                </p>
              </div>
              <div className={css.content_top_box_right}>
                <img
                  src={Rectangle3}
                  alt="Nixonbit Blockchain"
                  className={css.block_chain_img}
                />
              </div>
            </div>
            <div className={css.content_top_box}>
              <div className={css.content_top_box_left}>
                <img
                  src={Rectangle4}
                  alt="Nixonbit Blockchain"
                  className={css.block_chain_img}
                />
              </div>
              <div className={css.content_top_box_right}>
                <h3 className={css.content_top_box_heading}>
                  blockchain based market platform
                </h3>
                <p className={css.content_top_box_para}>
                  Tokenization platforms are digital platforms that facilitate
                  the issuance and management of digital securities. These
                  platforms use blockchain technology to track and manage the
                  ownership of digital securities. Tokenization platforms also
                  provide investors with a way to trade digital securities. Some
                  popular tokenization platforms include tZERO, Polymath, and
                  Securitize.
                </p>
              </div>
            </div>
            <div className={css.content_top_box}>
              <div className={css.content_top_box_left}>
                <h3 className={css.content_top_box_heading}>crypto wallets</h3>
                <p className={css.content_top_box_para}>
                  A cryptocurrency wallet is a digital wallet where private keys
                  are used to sign transactions in order to access public
                  addresses and store or spend cryptocurrency. Cryptocurrency
                  wallets can be used to store, send, and receive digital
                  currency like Bitcoin, Ethereum, and Litecoin.
                </p>
              </div>
              <div className={css.content_top_box_right}>
                <img
                  src={Rectangle5}
                  alt="Nixonbit Blockchain"
                  className={css.block_chain_img}
                />
              </div>
            </div>
            <div className={css.content_top_bottom_headings}>
              <h3 className={css.content_top_bottom_heading}>
                Every Part Of Your IT Ecosystem. Taken Care Of.
              </h3>
              <p className={css.content_top_bottom_para}>
                Comprehensive care of your cloud or on-premises infrastructure
                and applications
              </p>
            </div>
          </div>

          <div className={css.content_top_mbl}>
            <div className={css.content_top_headings_mbl_headings}>
              <h2 className={css.content_top_heading_mbl}>
                blockchain solutions
              </h2>
              <p className={css.content_top_para_mbl}>
                We have elected to put our money and faith in a mathematical
                framework that is free of politics and human error.
              </p>
            </div>
            {/* <div className={css.content_top_box_mbl1}>
              <img
                src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/block_chain_img.png"
                className={css.block_chain_img_mbl}
                alt="Nixonbit Blockchain"
              />
              <p className={css.content_top_box_para_mbl1}>
                Our team of experts has the experience and know-how to develop a
                blockchain solution that is tailored to your business needs. We
                can help you create a blockchain application that is secure,
                scalable, and easy to use
              </p>
            </div>
            <div className={css.content_top_box_mbl}>
              <img
                src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/Rectangle1.png"
                alt="Nixonbit Blockchain"
                className={css.block_chain_img_mbl}
              />
              <h3 className={css.content_top_box_heading_mbl}>
                private blockchain
              </h3>
              <p className={css.content_top_box_para_mbl}>
                Private blockchain networks are permissioned, meaning that
                access to the network is restricted to certain users. Private
                blockchains are often used for enterprise applications.
                Advantages of private blockchain networks include: Increased
                security: Private blockchains are more secure than public
                blockchains because they are not accessible to everyone. Private
                blockchains are more secure than public blockchains because they
                are not accessible to everyone.{" "}
              </p>
            </div>
            <div className={css.content_top_box_mbl}>
              <img
                src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/Rectangle2.png"
                alt="Nixonbit Blockchain"
                className={css.block_chain_img_mbl}
              />
              <h3 className={css.content_top_box_heading_mbl}>
                decentralised apps
              </h3>
              <p className={css.content_top_box_para_mbl}>
                Decentralised apps are applications that use blockchain
                technology to function. They are able to run on a network of
                computers rather than on a single server, meaning that they are
                not reliant on a single entity to function. Because of this,
                they are often seen as being more secure and resilient than
                traditional apps.
              </p>
            </div>
            <div className={css.content_top_box_mbl}>
              <img
                src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/Rectangle3.png"
                alt="Nixonbit Blockchain"
                className={css.block_chain_img_mbl}
              />
              <h3 className={css.content_top_box_heading_mbl}>
                smart contracts
              </h3>
              <p className={css.content_top_box_para_mbl}>
                Smart contracts are self-executing transactions with the terms
                of the agreement between buyer and seller being directly written
                into lines of code. Once the conditions are met, the contract is
                automatically executed. The advantage of smart contracts is that
                they can theoretically reduce the need for trust between
                parties, because the contract can only be executed if the
                conditions are met. This could potentially reduce costs
                associated with things like dispute resolution.
              </p>
            </div>
            <div className={css.content_top_box_mbl}>
              <img
                src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/Rectangle4.png"
                alt="Nixonbit Blockchain"
                className={css.block_chain_img_mbl}
              />

              <h3 className={css.content_top_box_heading_mbl}>
                blockchain based market platform
              </h3>
              <p className={css.content_top_box_para_mbl}>
                Tokenization platforms are digital platforms that facilitate the
                issuance and management of digital securities. These platforms
                use blockchain technology to track and manage the ownership of
                digital securities. Tokenization platforms also provide
                investors with a way to trade digital securities. Some popular
                tokenization platforms include tZERO, Polymath, and Securitize.
              </p>
            </div>
            <div className={css.content_top_box_mbl}>
              <img
                src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/Rectangle5.png"
                alt="Nixonbit Blockchain"
                className={css.block_chain_img_mbl}
              />
              <h3 className={css.content_top_box_heading_mbl}>
                crypto wallets
              </h3>
              <p className={css.content_top_box_para_mbl}>
                A cryptocurrency wallet is a digital wallet where private keys
                are used to sign transactions in order to access public
                addresses and store or spend cryptocurrency. Cryptocurrency
                wallets can be used to store, send, and receive digital currency
                like Bitcoin, Ethereum, and Litecoin.
              </p>
            </div> */}
          </div>
          <div className={css.content_middle}>
            <h2 className={css.content_heading}>GETTING STARTED</h2>
            <div className={css.content_middle_cards}>
              <div className={css.middle_left_cards}>
                <div className={css.middle__card}>
                  <h3 className={css.middle_card_heading}>Discovery</h3>
                  <p className={css.middle_card_para}>
                    We help you discover whether it makes sense to leverage
                    blockchain for your business needs.
                  </p>
                </div>
                <div className={css.middle__card}>
                  <h3 className={css.middle_card_heading}>integration</h3>
                  <p className={css.middle_card_para}>
                    We provide the tech tools and services to speed up your work
                    as a developer
                  </p>
                </div>
              </div>
              <div className={css.middle_right_cards}>
                <div className={css.middle__card}>
                  <h3 className={css.middle_card_heading}>inception</h3>
                  <p className={css.middle_card_para}>
                    We guide you to make the right choices for your business by
                    selecting the right tools, and technologies to meet your
                    requirements.
                  </p>
                </div>
                <div className={css.middle__card}>
                  <h3 className={css.middle_card_heading}>launch</h3>
                  <p className={css.middle_card_para}>
                    We go beyond development needs. We’ll be by your side to
                    help you get up and running
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={css.content_middle_mbl}>
            <h2 className={css.content_heading_mbl}>GETTING STARTED</h2>

            <div className={css.middle__card_mbl}>
              <h3 className={css.middle_card_heading_mbl}>Discovery</h3>
              <p className={css.middle_card_para_mbl}>
                We help you discover whether it makes sense to leverage
                blockchain for your business needs.
              </p>
            </div>
            <div className={css.middle__card_mbl}>
              <h3 className={css.middle_card_heading_mbl}>integration</h3>
              <p className={css.middle_card_para_mbl}>
                We provide the tech tools and services to speed up your work as
                a developer
              </p>
            </div>
            <div className={css.middle__card_mbl}>
              <h3 className={css.middle_card_heading_mbl}>inception</h3>
              <p className={css.middle_card_para_mbl}>
                We guide you to make the right choices for your business by
                selecting the right tools, and technologies to meet your
                requirements.
              </p>
            </div>
            <div className={css.middle__card_mbl}>
              <h3 className={css.middle_card_heading_mbl}>launch</h3>
              <p className={css.middle_card_para_mbl}>
                We go beyond development needs. We’ll be by your side to help
                you get up and running
              </p>
            </div>
          </div>
          <div className={css.content_bottom}>
            <h2 className={css.bottom_heading1}>WHY BUILD WITH SeiaSecure</h2>
            <p className={css.bottom_para1}>
              We built SeiaSecure to fuel the companies that are shaping the
              future. To give you the freedom and tools to build the next
              generation of digital innovation
            </p>
            <div className={css.bottom_points}>
              <div className={css.our_point}>
                <h3 className={css.point_heading}>we</h3>
                <p className={css.points}>
                  1. Build and maintain the technology infrastructure
                </p>
                <p className={css.points}>
                  2. Fit into your business and plug into your workflow
                </p>
                <p className={css.points}>
                  3. Bundle the best in class services and bring it to your
                  doorstep
                </p>
                <p className={css.points}>
                  4. Help guide you in your technical needs
                </p>
              </div>
              <div className={css.your_point}>
                <h3 className={css.point_heading}>you</h3>
                <p className={css.points}>
                  1. Own and control your customer experience
                </p>
                <p className={css.points}>
                  2. Build and enhance your product with our tech
                </p>
                <p className={css.points}>
                  3. Have a choice on how you work with us
                </p>
                <p className={css.points}>4. Focus on growing your business</p>
              </div>
            </div>
          </div>
        </div>
      </>
      <Footer />
    </>
  );
};

export default Blockchain;
