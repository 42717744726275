import css from "./Third.module.css";
import big_btn from "../../assets/big_btn.png";
import computer from "../../assets/computer.png";
import peoples from "../../assets/peoples.png";
import shake from "../../assets/shake.png";
import Blocks1 from "../../assets/Blocks1.png";
import block2 from "../../assets/block2.png";
import block3 from "../../assets/block3.png";
import block4 from "../../assets/block4.png";

const Third = () => {
  return (
    // <div className={css.third}>
    //   {/* <div className={css.third_top}>
    //     <h2 className={css.top_heading}>Our Clients</h2>
    //     <div className={css.top_logos}>
    //       <img
    //         src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara6.png"
    //         alt=""
    //         className={css.top_logo}
    //       />
    //       <img
    //         src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara7.png"
    //         alt=""
    //         className={css.top_logo}
    //       />
    //       <img
    //         src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara8.png"
    //         alt=""
    //         className={css.top_logo}
    //       />
    //       <img
    //         src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara4.png"
    //         alt=""
    //         className={css.top_logo}
    //       />
    //       <img
    //         src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara1.png"
    //         alt=""
    //         className={css.top_logo}
    //       />
    //     </div>
    //   </div> */}
    //   <div className={css.our_clients}>
    //     <h2 className={css.heading}>OUR CLIENTS</h2>
    //     <div className={css.slider}>
    //       <div className={css.slider_track}>
    //         {/* first */}
    //         <div className={css.slide}>
    //           <img
    //             src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara1.png"
    //             alt="Cybersecurity Services client logo"
    //           />
    //         </div>
    //         {/* <div className={css.slide}>
    //           <img
    //             src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara2.png"
    //             alt=""
    //           />
    //         </div>
    //         <div className={css.slide}>
    //           <img
    //             src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara3.png"
    //             alt=""
    //           />
    //         </div> */}
    //         <div className={css.slide}>
    //           <img
    //             src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara4.png"
    //             alt="Cybersecurity Services client logo"
    //           />
    //         </div>
    //         <div className={css.slide}>
    //           <img
    //             src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara5.png"
    //             alt="Cybersecurity Services client logo"
    //           />
    //         </div>
    //         <div className={css.slide}>
    //           <img
    //             src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara6.png"
    //             alt="Cybersecurity Services client logo"
    //           />
    //         </div>
    //         <div className={css.slide}>
    //           <img
    //             src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara7.png"
    //             alt=""
    //           />
    //         </div>
    //         <div className={css.slide}>
    //           <img src="https://dcred.io/assets/img/dcredLogo.svg" alt="" />
    //         </div>

    //         {/* secons */}

    //         <div className={css.slide}>
    //           <img
    //             src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara1.png"
    //             alt="Cybersecurity Services client logo"
    //           />
    //         </div>
    //         {/* <div className={css.slide}>
    //           <img
    //             src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara2.png"
    //             alt=""
    //           />
    //         </div>
    //         <div className={css.slide}>
    //           <img
    //             src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara3.png"
    //             alt=""
    //           />
    //         </div> */}
    //         <div className={css.slide}>
    //           <img
    //             src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara4.png"
    //             alt="Cybersecurity Services client logo"
    //           />
    //         </div>
    //         <div className={css.slide}>
    //           <img
    //             src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara5.png"
    //             alt="Cybersecurity Services client logo"
    //           />
    //         </div>
    //         <div className={css.slide}>
    //           <img
    //             src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara6.png"
    //             alt="Cybersecurity Services client logo"
    //           />
    //         </div>
    //         <div className={css.slide}>
    //           <img
    //             src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/cara7.png"
    //             alt="Cybersecurity Services client logo"
    //           />
    //         </div>
    //         <div className={css.slide}>
    //           <img
    //             src="https://dcred.io/assets/img/dcredLogo.svg"
    //             alt="Cybersecurity Services client logo"
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className={css.third_bottom}>
    //     <div className={css.big_btn}>
    //       <img src={big_btn} alt="" className={css._big_btn_img} />
    //       <p className={css.btn_para}>SUPPORT PROVIDED</p>
    //     </div>
    //     {/* <h3 className={css.bottom_heading}>
    //       JAISA KARMA KAREGA WAISA FAL DEGA BHAGWAN. YE HAI GEETA KA GYAN.... YE
    //       HAI GEETA KA GYAN..
    //     </h3> */}
    //     <div className={css.circles}>
    //       <div className={css.circle}>
    //         <p className={css.circle_para}>Security Tools.</p>
    //       </div>
    //       <div className={css.circle_color}>
    //         <p className={css.circle_para}>24/7 monitoring</p>
    //       </div>
    //       <div className={css.circle}>
    //         <p className={css.circle_para}>Increased efficiency</p>
    //       </div>
    //       <div className={css.circle_color}>
    //         <p className={css.circle_para}>Enhanced visibility</p>
    //       </div>
    //       <div className={css.circle}>
    //         <p className={css.circle_para}>Secure all time</p>
    //       </div>
    //       <div className={css.circle_color}>
    //         <p className={css.circle_para}>early threat detection</p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className={css.whyUs} id="why-us">
      <div className={css.whyUs_top}>
        <h3 className={css.top_Title}>WHY CHOOSE US</h3>
        <h2 className={css.top_title2}>
          Why The SeiaSecure Ranked Top Among The Leading IT Services & Software
          Development Company
        </h2>
        <p className={css.top_para1}>
          We started from scratch with a basic idea of becoming competent enough
          to analyze, understand and provide the best IT solutions to the
          seekers. the pillar of SeiaSecure, an IT firm based in noida, was
          established back in the year 2019 to creat a platform that can
          supervise and fulfill the it requirement of both emerging and
          established organizations.
        </p>
        <h2 className={css.top_title3}>
          A Reliable And Affordable Partner Delivering Value
        </h2>
        <p className={css.top_para1}>
          SeiaSecure is your all-in-one IT services and Software development
          company, with full IT consulting and delivering work from design to
          development and maintenance. <br /> SeiaSecure is dedicated to
          providing innovative solutions to our client while delivering
          best-in-class experience in each area of endeavour. We focus on
          delivering superior quality software development and IT consulting
          services.
        </p>
      </div>
      <div className={css.whyUs_middle}>
        <div className={css.middle_cards}>
          <div className={css.middle_card}>
            <div className={css.img_container}>
              <img src={computer} alt="Nixonbit Cybersecurity" />
            </div>
            <p className={css.middle_card_title}>
              Streamlined Project Management
            </p>
            <p className={css.middle_card_para}>
              We take a deep analysis and manage your project.
            </p>
          </div>
          <div className={css.middle_card}>
            <div className={css.img_container}>
              <img src={peoples} alt="Nixonbit Cybersecurity" />
            </div>
            <p className={css.middle_card_title}>A Dedicated Team Of Experts</p>
            <p className={css.middle_card_para}>
              We have the team of best experts and dedicated developers, Who
              always love challenges and innovations.
            </p>
          </div>
          <div className={css.middle_card}>
            <div className={css.img_container}>
              <img src={shake} alt="Nixonbit Cybersecurity" />
            </div>
            <p className={css.middle_card_title}>
              Completion Of Project In Given Time Limit With Perfection
            </p>
            <p className={css.middle_card_para}>
              We understand the value of time & Time is real Money. So our first
              goal is to delivery within given time limit with perfection
            </p>
          </div>
        </div>
        <h3 className={css.middle_title1}>
          Let's Start A New Project Together
        </h3>
        <h3 className={css.middle_title2}>
          Let's climb the success together, Change your idea into success.
        </h3>
        <button className={css.middle_btn}>request a quote </button>
      </div>
      <div className={css.whyUs_bottom}>
        <h2 className={css.bottom_title}>INFRASTURCTURE AND BUILDING BLOCKS</h2>
        <div className={css.bottom_cards}>
          <div className={css.bottom_card}>
            <div className={css.bottom_card_img}>
              <img src={Blocks1} alt="Nixonbit Cybersecurity" />
            </div>
            <div className={css.bottom_card_desc}>
              {/* <p className={css.bottom_card_title}>BLOCKCHAINS</p> */}
              {/* <p className={css.bottom_card_para}>
                Open and interconnected community owned database s and computer
                platform.
              </p> */}
            </div>
            {/* <img
              src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/one.png"
              alt="Nixonbit Cybersecurity"
              className={css.bottom_card_num}
            /> */}
          </div>
          <div className={css.bottom_card}>
            <div className={css.bottom_card_img}>
              <img src={block2} alt="Nixonbit Cybersecurity" />
            </div>
            {/* <div className={css.bottom_card_desc}>
              <p className={css.bottom_card_title}>SMART CONTRACTS</p>
              <p className={css.bottom_card_para}>
                Programmes enabling automation and execution of software on a
                decentralised computing platform.
              </p>
            </div> */}
            {/* <img
              src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/two.png"
              alt="Nixonbit Cybersecurity"
              className={css.bottom_card_num}
            /> */}
          </div>
          <div className={css.bottom_card}>
            <div className={css.bottom_card_img}>
              <img src={block3} alt="Nixonbit Cybersecurity" />
            </div>
            {/* <div className={css.bottom_card_desc}>
              <p className={css.bottom_card_title}>CRYPTOCURRENCIES</p>
              <p className={css.bottom_card_para}>
                Means to transfer value natively within digital ecosystem.
              </p>
            </div>
            <img
              src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/three.png"
              alt="Nixonbit Cybersecurity"
              className={css.bottom_card_num}
            /> */}
          </div>
          <div className={css.bottom_card}>
            <div className={css.bottom_card_img}>
              <img src={block4} alt="Nixonbit Cybersecurity" />
            </div>
            {/* <div className={css.bottom_card_desc}>
              <p className={css.bottom_card_title}>NFTs</p>
              <p className={css.bottom_card_para}>
                Blockchain-based, tokenized records that gurantee the unique
                identification of a digital assets.
              </p>
            </div>
            <img
              src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/four.png"
              alt="Nixonbit Cybersecurity"
              className={css.bottom_card_num}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Third;
