import React from "react";
import css from "./MiddleSection.module.css";

const MiddleSection = () => {
  return (
    <>
      <div className={css.maindiv2}>
        <div className={css.div3}>
          <p1 className={css.para3}>OUR DIGITAL MARKETING SERVICES</p1>
        </div>
        <div className={css.div9}>
          <div className={css.div11}>
            <div className={css.heading}>
              <p1 className={css.para1}>Search Engine Optimization (SEO)</p1>
            </div>
            <div className={css.list}>
              <ul>
                <li>Improve your website's visibility on search engines</li>
                <li>
                  Keyword research, on-page optimization, and backlink
                  strategies
                </li>
                <li>
                  Drive organic traffic and enhance search engine rankings
                </li>
              </ul>
            </div>
          </div>

          <div className={css.div11}>
            <div className={css.heading}>
              <p1 className={css.para1}>Pay-Per-Click (PPC) Advertising</p1>
            </div>
            <div className={css.list}>
              <ul>
                <li>
                  Targeted advertising campaigns on platforms like Google Ads
                  and social media
                </li>
                <li>
                  Keyword optimization, ad creation, and performance tracking
                </li>
                <li>Maximize ROI with data-driven PPC strategies</li>
              </ul>
            </div>
          </div>

          <div className={css.div11}>
            <div className={css.heading}>
              <p1 className={css.para1}>Social Media Marketing</p1>
            </div>
            <div className={css.list}>
              <ul>
                <li>
                  Engage your audience on platforms like Facebook, Instagram,
                  Twitter, and LinkedIn
                </li>
                <li>Content creation, scheduling, and community management</li>
                <li>Build brand awareness and foster customer relationships</li>
              </ul>
            </div>
          </div>

          <div className={css.div11}>
            <div className={css.heading}>
              <p1 className={css.para1}>Content Marketing</p1>
            </div>
            <div className={css.list}>
              <ul>
                <li>Develop and distribute high-quality, relevant content</li>
                <li>Blog posts, articles, videos, infographics, and more</li>
                <li>
                  Establish thought leadership and connect with your audience
                </li>
              </ul>
            </div>
          </div>

          <div className={css.div11}>
            <div className={css.heading}>
              <p1 className={css.para1}>Email Marketing</p1>
            </div>
            <div className={css.list}>
              <ul>
                <li>Create personalized email campaigns</li>
                <li>Targeted email lists, automation, and A/B testing</li>
                <li>Nurture leads and drive conversions</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MiddleSection;
