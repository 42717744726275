import css from "./footer.module.css";
import fb from "../../assets/fb.png";
import insta from "../../assets/insta.png";
import twitter from "../../assets/twitter.png";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <div className={css.footer} id="contact-us">
      <div className={css.main_footer}>
        <div className={css.part1}>
          <h3 className={css.heading1}>SeiaSecure® IT Solutions</h3>
          <p className={css.para1}>
            "We Strive to Revolutionize the World Through Cutting-Edge
            Technology."{" "}
          </p>
          <div className={css.img_links}>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img
                src={fb}
                alt="Cybersecurity Services fb logo"
                className={css.img1}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/seiasecure/"
            >
              <img
                src={insta}
                alt="Cybersecurity Services insta logo"
                className={css.img1}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/seiasecure"
            >
              <img
                src={twitter}
                alt="Cybersecurity Services X logo"
                className={css.img1}
              />
            </a>
          </div>
        </div>
        <div className={css.part2}>
          <p className={css.heading2}>SERVICES</p>
          <p className={css.para2}>Real-time threat monitoring</p>
          <p className={css.para2}>Proactive threat detection</p>
          <p className={css.para2}>Blockchain security</p>
          <p className={css.para2}>Expert security analysis</p>
          <p className={css.para2}>Data privacy</p>
        </div>
        <div className={css.part3}>
          <p className={css.heading3}>SUPPORT</p>
          <p className={css.para3}>Early threats detection</p>
          <p className={css.para3}>Security tools</p>
          <p className={css.para3}>24/7 monitoring</p>
          <p className={css.para3}>Increased efficiency</p>
          <p className={css.para3}>Enhanced visibility</p>
          <p className={css.para3}>Secure all time</p>
        </div>
        <div className={css.part4}>
          <p className={css.heading4}>COMPANY</p>
          <a
            href="tel:+91 8595932853"
            className={css.para4}
            style={{ textDecoration: "none", color: "#ffffff" }}
          >
            +91 8595932853
          </a>
          <a
            href="mailto:info@seiasecure.com"
            className={css.para4}
            style={{ textDecoration: "none", color: "#ffffff" }}
          >
            info@seiasecure.com{" "}
          </a>
          <p className={css.para4}>
            PKT - 11B, SEC. - 23, Rohini, New Delhi - 110085, INDIA
          </p>
        </div>
      </div>
      <p className={css.copyright}>
        All Rights Reserved. © Copyright 2023. SeiaSecure IT Solutions
      </p>
    </div>
  );
};

export default Footer;
