import css from "./Contact.module.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const initialFormData = {
    email: "",
    first_name: "",
    for_company: "SeiaSecure",
    last_name: "",
    message: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Form validation
    if (
      formData.first_name.trim() === "" ||
      formData.last_name.trim() === "" ||
      formData.email.trim() === ""
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(formData.email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    // Form is valid, proceed with submission
    axios
      .post("https://dev.seiasecure.com/api/v1/web_form", formData)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Thanks! Team NixonBit Will Contact You");
          setFormData(initialFormData);
        } else {
          toast.error("Submission failed. Please try again.");
        }
      })
      .catch((error) => {
        toast.error("Submission failed. Please try again.");
      });
  };

  return (
    <>
      <Header />
      <>
        <div className={css.contact}>
          <div className={css.contact_left}>
            <h3 className={css.heading_one}>contact us</h3>
            <h2 className={css.heading_two}>come talk with us</h2>
            <p className={css.para_one}>
              SeiaSecure is an IT Services & Software Development Company of
              this Era which aims at quality, unparalleled innovation, sharing
              creative thinking and ideas to provide quality services.
            </p>
            <a
              href="info@seiasecure.com"
              className={css.mail_box}
              style={{ textDecoration: "none" }}
            >
              {/* <img
                src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/mail.png"
                alt="Nixonbit Digital marketing"
              /> */}
              <div className={css.mail_details}>
                <p className={css.mail_title}>Email</p>
                <p className={css.mail_address}>info@seiasecure.com</p>
              </div>
            </a>
            <a
              href="tel:+91 9304345393"
              className={css.call_box}
              style={{ textDecoration: "none" }}
            >
              {/* <img
                src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/call.png"
                alt="Nixonbit Digital marketing"
              /> */}
              <div className={css.call_details}>
                <p className={css.call_title}>Phone</p>
                <p className={css.call_address}> +91 8595932853</p>
              </div>
            </a>
            <div className={css.address_box}>
              {/* <img
                src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/loaction.png"
                alt="Nixonbit Digital marketing"
              /> */}
              <div className={css.address_details}>
                <p className={css.address_title}>address</p>
                <p className={css.address_address}>
                  PKT - 11B, SEC. - 23, Rohini, New Delhi - 110085, INDIA
                </p>
              </div>
            </div>
            <div className={css.img_links}>
              <a
                className={css.img_link}
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/NixonBit_India"
              >
                {/* <img
                  src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/Twitter_white.png"
                  className={css.contact_img_links}
                  alt="Nixonbit Digital marketing"
                /> */}
              </a>
              <a
                className={css.img_link}
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/nixonbitindia"
              >
                {/* <img
                  src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/facebook_white.png"
                  className={css.contact_img_links}
                  alt="Nixonbit Digital marketing"
                /> */}
              </a>
              <a
                className={css.img_link}
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/nixonbitindia/"
              >
                {/* <img
                  src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/Instagram_white.png"
                  className={css.contact_img_links}
                  alt="Nixonbit Digital marketing"
                /> */}
              </a>
              <a
                className={css.img_link}
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/nixonbit/"
              >
                {/* <img
                  src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/linkedIn_white.png"
                  className={css.contact_img_links}
                  alt="Nixonbit Digital marketing"
                /> */}
              </a>
            </div>
          </div>
          <form className={css.contact_right} onSubmit={handleSubmit}>
            <div className={css.input_box}>
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                placeholder="First Name"
                className={css.fName_input}
                required
              />
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                placeholder="Last Name"
                className={css.lName_input}
                required
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email"
                className={css.email_input}
                required
              />
              <textarea
                type="text"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                placeholder="Message"
                className={css.message_input}
              />
            </div>
            <button className={css.contact_btn} type="submit">
              Send
            </button>
          </form>
        </div>
      </>

      <Footer />
      <ToastContainer />
    </>
  );
};

export default Contact;
