import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import css from "./Softwaredevelopment.module.css";
import TopSection from "./components/TopSection";
import MiddleSection from "./components/MiddleSection";
import BottomSection from "./components/BottomSection";



const SoftwareDevelopment = () => {
  return (
    <>
      <Header />
      <TopSection/>
      <MiddleSection/>
      <BottomSection/>
      <Footer />
    </>
  );
};

export default SoftwareDevelopment;
