import React from "react";
import css from "./TopSection.module.css";
import sdhero from "../../../assets/sdhero.png";

const TopSection = () => {
  return (
    <>
      <div className={css.maindiv}>
        <div className={css.header}>
          <p1 className={css.para1}>
            ELEVATE YOUR BUSINESS WITH SOFTWARE DEVELOPMENT SOLUTIONS
          </p1>
        </div>
        <div className={css.imgdiv}>
          <img
            src={sdhero}
            className={css.image1}
            alt="Nixonbit Blockchain"
          ></img>
        </div>
        <div className={css.paradiv}>
          <p1 className={css.para2}>
            At SeiaSecure, we believe that the right software can transform your
            business. With our expertise in software development, we empower
            organizations to achieve their goals, streamline operations, and
            stay ahead of the competition. Discover how our tailored software
            solutions can drive your success.
          </p1>
        </div>
      </div>
    </>
  );
};

export default TopSection;
