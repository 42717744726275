import React from "react";
import css from "./TopSection.module.css";
import aihero from "../../../assets/aihero.png";

const TopSection = () => {
  return (
    <>
      <div className={css.maindiv}>
        <div className={css.div2}>
          <img src={aihero} className={css.img1} alt="Nixonbit AI"></img>
        </div>
        <div className={css.div1}>
          <div className={css.paradiv1}>
            <p1 className={css.para1}>NixonBit AI/ML</p1>
          </div>
          <div className={css.paradiv2}>
            <p1 className={css.para2}>
              At NixonBit, we pride ourselves on our expertise in artificial
              intelligence and machine learning (AI/ML), offering cutting-edge
              solutions tailored to meet the diverse needs of modern businesses.
              With a focus on innovation and efficiency, we specialize in a wide
              range of AI/ML applications, including chatbots, computer vision,
              and targeted marketing solutions.
            </p1>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopSection;
