export const MenuItems = [
  {
    title: "Blockchain",
    path: "/blockchain",
    cName: "dropdown-link",
  },
  {
    title: "Audit",
    path: "/audit",
    cName: "dropdown-link",
  },
  {
    title: "Cybersecurity",
    path: "/cybersecurity",
    cName: "dropdown-link",
  },
  {
    title: "Software Development",
    path: "/software-development",
    cName: "dropdown-link",
  },
  {
    title: "AI(Chat GPT)",
    path: "/ai-chat-gpt",
    cName: "dropdown-link",
  },
  {
    title: "Digital Marketing",
    path: "/digital-marketing",
    cName: "dropdown-link",
  },
  {
    title: "Machine Learning",
    path: "/machine-learning",
    cName: "dropdown-link",
  },
  {
    title: "Data Science",
    path: "/data-science",
    cName: "dropdown-link",
  },
  {
    title: "Web App Development",
    path: "/web-app-development",
    cName: "dropdown-link",
  },
  {
    title: "Mobile App Development",
    path: "/mobile-app-development",
    cName: "dropdown-link",
  },
  {
    title: "Bot Service",
    path: "/bot-service",
    cName: "dropdown-link",
  },
  {
    title: "Cloud Service",
    path: "/cloud-service",
    cName: "dropdown-link",
  },
  {
    title: "Forensic Tools",
    path: "/forensic Tools",
    cName: "dropdown-link",
  },
];
