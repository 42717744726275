import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import css from "./Audit.module.css";
import image1 from "../../assets/image1.png";
import money from "../../assets/money.png";
import starBoy from "../../assets/starBoy.png";
import lockk from "../../assets/lockk.png";
import lock from "../../assets/lock.png";
import proDoc from "../../assets/proDoc.png";
import dock from "../../assets/dock.png";
import pic1 from "../../assets/pic1.png";
import pic3 from "../../assets/pic3.png";
import logoo from "../../assets/logoo.jpg";

const Audit = () => {
  return (
    <>
      <Header />
      <>
        <div className={css.audit}>
          <div className={css.audit_hero}>
            <div className={css.hero_left}>
              <h2 className={css.hero_heading}>
                Build With Private And Secure Transaction Infrastructure
              </h2>
              <button className={css.hero_btn}>free consultation</button>
            </div>
            <div className={css.hero_right}>
              <img src={image1} alt="Nixonbit Audit" />
            </div>
          </div>
          <div className={css.contracts}>
            <h2 className={css.contract_heading}>
              Why Do We Need Smart Contract Audits?
            </h2>
            <div className={css.contract_cards}>
              <div className={css.contract_card}>
                <div className={css.contract_img_box}>
                  <img src={money} alt="Nixonbit Audit" />
                </div>
                <p className={css.contract_para}>
                  Avoid costly errors: Auditing your code early in the
                  development lifecycle can help you avoid potentially fatal
                  flaws after launch.
                </p>
              </div>
              <div className={css.contract_card}>
                <div className={css.contract_img_box}>
                  <img src={starBoy} alt="Nixonbit Audit" />
                </div>
                <p className={css.contract_para}>
                  Expert review: To eliminate spurious results, veteran security
                  auditors manually double-check your code.
                </p>
              </div>
              <div className={css.contract_card}>
                <div className={css.contract_img_box}>
                  <img src={lockk} alt="Nixonbit Audit" />
                </div>
                <p className={css.contract_para}>
                  Prevent security attacks: As you write and alter code, keeping
                  an eye on any security flaws helps prevent security attacks.
                </p>
              </div>
              <div className={css.contract_card}>
                <div className={css.contract_img_box}>
                  <img src={lock} alt="Nixonbit Audit" />
                </div>
                <p className={css.contract_para}>
                  Enhanced security: Smart contract security audit assures the
                  owners of decentralised products that their code is secure.
                </p>
              </div>
              <div className={css.contract_card}>
                <div className={css.contract_img_box}>
                  <img src={proDoc} alt="Nixonbit Audit" />
                </div>
                <p className={css.contract_para}>
                  Continuous security assessment: smart contract auditing
                  process allows you to conduct security assessments, to improve
                  your development environment.
                </p>
              </div>
              <div className={css.contract_card}>
                <div className={css.contract_img_box}>
                  <img src={dock} alt="Nixonbit Audit" />
                </div>
                <p className={css.contract_para}>
                  Analytical reports: Receive an executive summary,
                  vulnerability details and mitigation advice in a vulnerability
                  report.
                </p>
              </div>
            </div>
          </div>
          <div className={css.audit_types}>
            <h2 className={css.audit_types_heading}>Types Of Audits</h2>
            <div className={css.audit_types_top}>
              <div className={css.audit_type_card}>
                <img src={pic1} alt="Nixonbit Audit" />
                <h3 className={css.audit_types_card_headinh}>
                  Token Contract:
                </h3>
                <p className={css.audit_types_card_para}>
                  we can test smart contracts on any protocol and programming
                  language like solidity and javascript
                </p>
              </div>
              <div className={css.audit_type_card}>
                <img src={pic3} alt="Nixonbit Audit" />
                <h3 className={css.audit_types_card_headinh}>
                  Custom Smart Contract:
                </h3>
                <p className={css.audit_types_card_para}>
                  we develope the main conception of a company according to
                  company’s targetand develop strategies of competative
                  advantage
                </p>
              </div>
              <div className={css.audit_type_card}>
                <img src={pic1} alt="Nixonbit Audit" />
                <h3 className={css.audit_types_card_headinh}>
                  blockchain wallet and dapps:
                </h3>
                <p className={css.audit_types_card_para}>
                  our leading cybersecurity expert assessment helps you assess
                  the security of blockchain wallet and d-apps to keep your
                  assets safe from hackers
                </p>
              </div>
            </div>
            <div className={css.audit_types_bottom}>
              <div className={css.audit_types_bottom_right}>
                <img src={logoo} alt="Nixonbit Audit" />
              </div>
              <div className={css.audit_types_bottom_left}>
                <div className={css.quality}>
                  <h3 className={css.quality_heading}>quality:</h3>

                  <p className={css.quality_para}>
                    our customers come list on famous exchanges. extensive
                    experience and a large number of successful audits
                  </p>
                </div>
                <div className={css.quality}>
                  <h3 className={css.quality_heading}>report:</h3>
                  <p className={css.quality_para}>
                    we powered audits provide a comprehensive vulnerabilities
                    report as well as detailed code change recommendations
                  </p>
                </div>
                <div className={css.quality}>
                  <h3 className={css.quality_heading}>oppertunities:</h3>
                  <p className={css.quality_para}>
                    we work with popular blockchain: ethereum, polygon, fantom
                    and more.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={css.audit_work}>
            <h2 className={css.audit_work_heading}>how we work</h2>
            <div className={css.audit_work_steps}>
              <div className={css.audit_work_step}>
                <p className={css.step_num}>STEP 1</p>
                {/* <img
                  src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/Arrow1.png"
                  alt="Nixonbit Audit"
                  className={css.arrow_img}
                /> */}
                <p className={css.step_heading}>preparation</p>
                <p className={css.step_para}>
                  supply our team with audit ready code and additional material
                </p>
              </div>
              <div className={css.audit_work_step}>
                <p className={css.step_num}>STEP 2</p>
                {/* <img
                  src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/Arrow1.png"
                  alt="Nixonbit Audit"
                  className={css.arrow_img}
                /> */}
                <p className={css.step_heading}>communication</p>
                <p className={css.step_para}>
                  we set up a real time communication tool of your choice or
                  communicate via email
                </p>
              </div>
              <div className={css.audit_work_step}>
                <p className={css.step_num}>STEP 3</p>
                {/* <img
                  src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/Arrow1.png"
                  alt="Nixonbit Audit"
                  className={css.arrow_img}
                /> */}
                <p className={css.step_heading}>audit</p>
                <p className={css.step_para}>
                  we conduct the audit, suggesting fixes to all vulnerability
                  and help you to improve.
                </p>
              </div>
              <div className={css.audit_work_step}>
                <p className={css.step_num}>STEP 4</p>
                {/* <img
                  src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/Arrow1.png"
                  alt="Nixonbit Audit"
                  className={css.arrow_img}
                /> */}
                <p className={css.step_heading}>fixes </p>
                <p className={css.step_para}>
                  you development team applies fixes while consulting with our
                  auditors on their safety
                </p>
              </div>
              <div className={css.audit_work_step}>
                <p className={css.step_num}>STEP 5</p>
                {/* <img
                  src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/Arrow1.png"
                  alt="Nixonbit Audit"
                  className={css.arrow_img}
                /> */}
                <p className={css.step_heading}>report</p>
                <p className={css.step_para}>
                  we check the applies fixes and deliver a full report on all
                  steps done
                </p>
              </div>
            </div>
          </div>
          <div className={css.audit_process}>
            <h2 className={css.process_heading}>
              Smart Contract Audit Process
            </h2>
            <p className={css.process_para}>
              The audit process of a smart contract generally includes the
              following steps:
            </p>
            {/* <img
              src="https://nixonbit-public.s3.ap-south-1.amazonaws.com/assets/pointer.png"
              alt="Nixonbit Audit"
              className={css.process_img}
            /> */}
            <div className={css.process_points}>
              <p className={css.point_para_up}>
                Review the code to identify potential security vulnerabilities.
              </p>
              <p className={css.point_para}>
                Understand the business logic and functionality of the smart
                contract.
              </p>
              <p className={css.point_para_up}>
                Evaluate the security of the smart contract.
              </p>
              <p className={css.point_para}>
                Perform a security review of the smart contract.
              </p>
              <p className={css.point_para_up}>
                Generate a report detailing the findings of the audit.
              </p>
            </div>
            <div className={css.process_cost}>
              <h2 className={css.cost_heading}>
                How Much Does A Smart Contract Audit Cost?
              </h2>
              <p className={css.cost_para}>
                There is no definitive answer to this question as the cost of a
                smart contract audit will vary depending on the size and
                complexity of the contract, as well as the specific needs of the
                client. However, a rough estimate for a simple smart contract
                audit would be provided after your free consultation.
              </p>
              <h3 className={css.cost_heading2}>
                Book Your Free Consultation Now
              </h3>
              <button className={css.cost_btn}>free consultation </button>
            </div>
          </div>
        </div>
      </>
      <Footer />
    </>
  );
};

export default Audit;
