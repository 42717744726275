import React from "react";
import css from "./BottomSection.module.css";
import dm1 from "../../../assets/dm1.png";
import dm2 from "../../../assets/dm2.png";
import dm3 from "../../../assets/dm3.png";
import dm4 from "../../../assets/dm4.png";
import dm5 from "../../../assets/dm5.png";

const BottomSection = () => {
  return (
    <>
      <div className={css.maindiv}>
        <div className={css.div1}>
          <p1 className={css.para1}>WHY CHOOSE SeiaSecure:</p1>
          <div className={css.div2}>
            <div className={css.div3}>
              <div
                className={css.div4}
                style={{
                  backgroundImage: `url(${dm1})`,
                  backgroundSize: "cover",
                }}
              ></div>

              <div className={css.div5}>
                <p1 className={css.para2}>Expertise</p1>
              </div>

              <div className={css.div6}>
                <p1 className={css.para3}>
                  Our digital marketing specialists have a deep understanding of
                  the digital landscape and the latest industry trends.
                </p1>
              </div>
            </div>

            <div className={css.div3}>
              <div
                className={css.div14}
                style={{
                  backgroundImage: `url(${dm2})`,
                  backgroundSize: "cover",
                }}
              ></div>

              <div className={css.div5}>
                <p1 className={css.para2}>Customized Strategies</p1>
              </div>

              <div className={css.div6}>
                <p1 className={css.para3}>
                  We tailor our strategies to your unique business goals and
                  target audience.
                </p1>
              </div>
            </div>

            <div className={css.div3}>
              <div
                className={css.div4}
                style={{
                  backgroundImage: `url(${dm3})`,
                  backgroundSize: "cover",
                }}
              ></div>

              <div className={css.div5}>
                <p1 className={css.para2}>Measurable Results</p1>
              </div>

              <div className={css.div6}>
                <p1 className={css.para3}>
                  We focus on achieving measurable results and delivering a
                  strong return on your investment.
                </p1>
              </div>
            </div>

            <div className={css.div3}>
              <div
                className={css.div14}
                style={{
                  backgroundImage: `url(${dm4})`,
                  backgroundSize: "cover",
                }}
              ></div>

              <div className={css.div5}>
                <p1 className={css.para2}>Collaborative Approach</p1>
              </div>

              <div className={css.div6}>
                <p1 className={css.para3}>
                  We work closely with you to understand your brand and vision,
                  ensuring our efforts align with your objectives.
                </p1>
              </div>
            </div>

            <div className={css.div3}>
              <div
                className={css.div4}
                style={{
                  backgroundImage: `url(${dm5})`,
                  backgroundSize: "cover",
                }}
              ></div>

              <div className={css.div5}>
                <p1 className={css.para2}>Continuous Optimization</p1>
              </div>

              <div className={css.div6}>
                <p1 className={css.para3}>
                  We continuously analyze data and optimize campaigns for
                  ongoing success.
                </p1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BottomSection;
